import React from "react";
import { TextField } from "@mui/material";

class FormInput extends React.Component {
  render() {
    return (
      <>
        <TextField
          {...this.props}
          sx={{
            my: 1,
          }}
          id={this.props.label}
          variant="outlined"
          label={this.props.label}
          onChange={(e) => {
            if (this.props.onValueChanged != null)
              this.props.onValueChanged(e.target.value);
          }}
          onBlur={(e) => {
            if (this.props.onBlur != null) this.props.onBlur(e.target.value);
          }}
        />
      </>
    );
  }
}

//String Input is a normal input but can just be used for uniformity purposes
class StringInput extends React.Component {
  render() {
    return <FormInput {...this.props} />;
  }
}

class IntegerInput extends React.Component {
  render() {
    return (
      <FormInput
        {...this.props}
        onValueChanged={(input) => {
          if (input == "") input = "0";
          this.props.onValueChanged(parseInt(input));
        }}
        value={this.props.value}
      />
    );
  }
}

class FloatInput extends React.Component {
  render() {
    return (
      <FormInput
        {...this.props}
        onBlur={(input) => {
          if (input == "") input = "0";
          this.props.onValueChanged(parseFloat(input));
        }}
        value={this.props.value}
      />
    );
  }
}

export { FormInput, StringInput, IntegerInput, FloatInput };
