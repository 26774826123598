import Constants from "../Constants";


const usernameKey = "un";
const passwordKey = "wp";

const StorageHelper = {


    isLoggedIn: () => {
        return localStorage.getItem(Constants.LOCAL_STORAGE_KEY + usernameKey);
    },

    setAuthData: (username, password) => {
        try {

            //Save username
            localStorage.setItem(Constants.LOCAL_STORAGE_KEY + usernameKey, username);
            //Save password (If this is necessary at all)
            localStorage.setItem(Constants.LOCAL_STORAGE_KEY + passwordKey, password);
            return true;
        } catch (error) {
            console.log("[Error] Saving auth Data", error);
            return false;
        }
    },

    getAuthData: () => {
        var username = localStorage.getItem(Constants.LOCAL_STORAGE_KEY + usernameKey);
        var password = localStorage.getItem(Constants.LOCAL_STORAGE_KEY + passwordKey);

        return {
            success: true,
            username,
            password
        };
    },

    clearAuthData: () => {
        //Save username
        localStorage.removeItem(Constants.LOCAL_STORAGE_KEY + usernameKey);
        //Save password (If this is necessary at all)
        localStorage.removeItem(Constants.LOCAL_STORAGE_KEY + passwordKey);
    }
}

export default StorageHelper;