import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import Constants from "../Constants";
import StorageHelper from "../helpers/StorageHelper";
import "./Spins.css";
import StripedDataGrid from "./generic/StripedDataGrid";
import { Tooltip, Button } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
export default function Opens() {
  const [loading, setLoading] = useState(true);
  const [fetchedOpens, setFetchedOpens] = useState([]);
  const [opens, setOpens] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const payload = {
        filter: {},
      };
      console.log("Constants.API_URL", Constants.API_URL);
      console.log("payload", payload);
      try {
        let res = await axios.get(Constants.API_URL + "/opens", payload);
        console.log("res", res);
        let data = res.data;
        console.log("data", data);
        setFetchedOpens([...data.opens.slice().reverse()]);
        setOpens(data.opens.slice().reverse());
        setLoading(false);
      } catch (error) {
        console.log("error", error);
      }
    }

    fetchData();
  }, []);

  console.log("opens", opens);

  const deleteOpen = async function (_id, index) {
    var authData = StorageHelper.getAuthData();
    const payload = {
      data: {
        username: authData.username,
        password: authData.password,
        id: _id,
      },
    };
    console.log("Constants.API_URL", Constants.API_URL);
    console.log("payload", payload);
    try {
      let res = await axios.delete(Constants.API_URL + "/opens", {
        ...payload,
      });
      console.log("res", res);
      res = res.data;
      if (res.success) {
        if (res.result.deletedCount > 0) {
          alert("Open deleted successfully");

          //Update client open data
          const fchdOpens = [...fetchedOpens];
          const oldOpens = [...opens];
          const fchdOpensIndex = oldOpens.findIndex((open) => {
            const open_id = open._id.toString();
            return open_id == _id;
          });
          fchdOpens.splice(fchdOpensIndex, 1);
          oldOpens.splice(index, 1);
          setFetchedOpens([...fchdOpens]);
          setOpens([...oldOpens]);
        } else {
          alert("No open data was deleted");
        }
      }

      setLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  const tableCols = [
    { field: "id", headerName: "ID", width: 150 },
    { field: "user", headerName: "User", width: 150 },
    { field: "stamina", headerName: "Stamina", width: 150 },
    { field: "durability", headerName: "Durability", width: 150 },
    { field: "open_date", headerName: "Open Date", width: 150 },
    {
      field: "controls",
      headerName: "Controls",
      width: 200,
      renderCell: (params) => (
        <>
          <Tooltip title="Delete">
            <Button
              variant="contained"
              color="error"
              key={"delete_" + params.id}
              sx={{ ml: 2 }}
              onClick={() => {
                var confirmed = window.confirm(
                  "Are you sure you want to delete data for open:" +
                    params.row.user +
                    "?"
                );
                if (confirmed) deleteOpen(params.id, params.row.index);
              }}
            >
              <DeleteIcon />{" "}
            </Button>
          </Tooltip>
        </>
      ),
    },
  ];

  const tableRows = useMemo(() => {
    return opens.map((_data, index) => {
      const id = _data._id.toString();
      console.log({ _data });
      return {
        ..._data,
        id,
        index,
        open_date: new Date(_data.open_date).toUTCString(),
      };
    });
  }, [opens]);

  return (
    <div style={loading ? { textAlign: "center" } : {}}>
      {loading ? (
        <h3>Fetching data...</h3>
      ) : (
        <div>
          <div style={{ height: "80vh", width: "100%" }}>
            <StripedDataGrid
              rows={tableRows}
              columns={tableCols}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
              }
            />
          </div>
        </div>
      )}
    </div>
  );
}
