import axios from "axios";
import React, { useEffect, useState } from "react";
import Constants from "../Constants";
import StorageHelper from "../helpers/StorageHelper";
import { Button, Typography } from "@mui/material";
export default function Maintenance() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [responseColor, setResponseColor] = useState("blue");
  const [responseMessage, setResponseMessage] = useState("");

  useEffect(() => {
    var authData = StorageHelper.getAuthData();
    setUsername(authData.username);
    setPassword(authData.password);
  }, []);

  const shutdownGracefully = async () => {
    const payload = {
      username,
      password,
    };
    console.log("Constants.API_URL", Constants.API_URL);
    console.log("payload", payload);
    try {
      let res = await axios.post(Constants.API_URL + "/shutdown", payload);
      console.log("res", res);
      let data = res.data;

      if (data.success) {
        setResponseColor("green");
        setResponseMessage("Shutdown rooms successfully.");
      } else {
        setResponseColor("red");
        setResponseMessage(data.error);
      }
    } catch (e) {
      console.log("error", e);
      setResponseColor("red");
      setResponseMessage("Shutdown rooms failed");
    }
  };

  const deleteMatchHistory = async () => {
    const payload = {
      username,
      password,
    };
    console.log("Constants.API_URL", Constants.API_URL);
    console.log("payload", payload);
    try {
      let res = await axios.delete(Constants.API_URL + "/allMatchHistory", {
        ...payload,
      });
      console.log("res", res);
      res = res.data;
      if (res.success) {
        setResponseColor("green");
        setResponseMessage("Delete Match History successfully.");
      } else {
        setResponseColor("red");
        setResponseMessage(res.error);
      }
    } catch (e) {
      console.log("error", e);
      setResponseColor("red");
      setResponseMessage("Delete Match History failed");
    }
  };
  

  const deleteStatPlacements = async () => {
    const payload = {
      username,
      password,
    };
    console.log("Constants.API_URL", Constants.API_URL);
    console.log("payload", payload);
    try {
      let res = await axios.delete(Constants.API_URL + "/allStatPlacements", {
        ...payload,
      });
      console.log("res", res);
      res = res.data;
      if (res.success) {
        setResponseColor("green");
        setResponseMessage("Delete Stat Placements successfully.");
      } else {
        setResponseColor("red");
        setResponseMessage(res.error);
      }
    } catch (e) {
      console.log("error", e);
      setResponseColor("red");
      setResponseMessage("Delete Stat Placements failed");
    }
  };

  const deleteCardRoundStats = async () => {
    const payload = {
      username,
      password,
    };
    console.log("Constants.API_URL", Constants.API_URL);
    console.log("payload", payload);
    try {
      let res = await axios.delete(Constants.API_URL + "/allCardRoundStats", {
        ...payload,
      });
      console.log("res", res);
      res = res.data;
      if (res.success) {
        setResponseColor("green");
        setResponseMessage("Delete Card Rounds successfully.");
      } else {
        setResponseColor("red");
        setResponseMessage(res.error);
      }
    } catch (e) {
      console.log("error", e);
      setResponseColor("red");
      setResponseMessage("Delete Card Rounds failed");
    }
  };


  const deletePermaStats = async () => {
    const payload = {
      username,
      password,
    };
    console.log("Constants.API_URL", Constants.API_URL);
    console.log("payload", payload);
    try {
      let res = await axios.delete(Constants.API_URL + "/allPerma", {
        ...payload,
      });
      console.log("res", res);
      res = res.data;
      if (res.success) {
        setResponseColor("green");
        setResponseMessage("Deleted perma stats successfully.");
      } else {
        setResponseColor("red");
        setResponseMessage(res.error);
      }
    } catch (e) {
      console.log("error", e);
      setResponseColor("red");
      setResponseMessage("Delete perma stats failed");
    }
  };
  return (
    <div className="container">
      <Typography style={{ color: responseColor }}>
        {responseMessage}
      </Typography>
      <br />
      <br />
      {/**
         * 
        <Button className="btn1 bg-green w50" variant="contained" onClick={() => {
            shutdownGracefully();
        }} >Shutdown Rooms</Button>
         */}

<Button
        variant="contained"
        onClick={() => {
          deleteMatchHistory();
        }}
        sx={{
            ml: 5
        }}
      >
        Clear Match History
      </Button>
      <Button
        variant="contained"
        onClick={() => {
          deleteCardRoundStats();
        }}
        sx={{
            ml: 5
        }}
      >
        Clear Card Round Stats
      </Button>
      <Button
        variant="contained"
        onClick={() => {
          deleteStatPlacements();
        }}
        sx={{
            ml: 5
        }}
      >
        Clear Stat Placements
      </Button>
      <Button
        variant="contained"
        onClick={() => {
          deletePermaStats();
        }}
        sx={{
            ml: 5
        }}
      >
        Clear Permanent Stats
      </Button>
    </div>
  );
}
