import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { alpha, styled } from "@mui/material/styles";

const FullWidthDialog = styled(Dialog)(({ theme }) => ({
    [`& .MuiPaper-root`]: {
        width: "100%"
    },
  }));

export default function FormDialog(props) {
    const {open, title, description, children, onSubmit, setOpen, processing = false} = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <FullWidthDialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {description}
          </DialogContentText>
          {children}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={processing} onClick={onSubmit}>Submit</Button>
        </DialogActions>
      </FullWidthDialog>
    </div>
  );
}
