"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScatterUser = void 0;
const eosjs_1 = require("eosjs");
const elliptic_1 = require("elliptic");
const eosjs_jssig_1 = require("eosjs/dist/eosjs-jssig");
const universal_authenticator_library_1 = require("universal-authenticator-library");
const UALScatterError_1 = require("./UALScatterError");
const { KeyType } = eosjs_1.Numeric;
const ec = new elliptic_1.ec('secp256k1');
class ScatterUser extends universal_authenticator_library_1.User {
    constructor(chain, scatter) {
        super();
        this.chain = chain;
        this.scatter = scatter;
        this.keys = [];
        this.accountName = '';
        const rpcEndpoint = this.chain.rpcEndpoints[0];
        const rpcEndpointString = this.buildRpcEndpoint(rpcEndpoint);
        this.rpc = new eosjs_1.JsonRpc(rpcEndpointString);
        const network = {
            blockchain: 'eos',
            chainId: this.chain.chainId,
            protocol: rpcEndpoint.protocol,
            host: rpcEndpoint.host,
            port: rpcEndpoint.port,
        };
        const rpc = this.rpc;
        this.api = this.scatter.eos(network, eosjs_1.Api, { rpc });
    }
    signTransaction(transaction, { broadcast = true, blocksBehind = 3, expireSeconds = 30 }) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const completedTransaction = yield this.api.transact(transaction, { broadcast, blocksBehind, expireSeconds });
                return this.returnEosjsTransaction(broadcast, completedTransaction);
            }
            catch (e) {
                throw new UALScatterError_1.UALScatterError('Unable to sign the given transaction', universal_authenticator_library_1.UALErrorType.Signing, e);
            }
        });
    }
    verifyKeyOwnership(challenge) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    reject(new Error('verifyKeyOwnership failed'));
                }, 1000);
                this.authenticate(challenge, resolve);
            });
        });
    }
    signArbitrary(publicKey, data, _) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.scatter.getArbitrarySignature(publicKey, data);
        });
    }
    getAccountName() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.accountName) {
                yield this.refreshIdentity();
            }
            return this.accountName;
        });
    }
    getChainId() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.chain.chainId;
        });
    }
    getKeys() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.keys || this.keys.length === 0) {
                yield this.refreshIdentity();
            }
            return this.keys;
        });
    }
    refreshIdentity() {
        return __awaiter(this, void 0, void 0, function* () {
            const rpcEndpoint = this.chain.rpcEndpoints[0];
            try {
                const identity = yield this.scatter.getIdentity({
                    accounts: [{
                            blockchain: 'eos',
                            host: rpcEndpoint.host,
                            port: rpcEndpoint.port,
                            chainId: this.chain.chainId
                        }]
                });
                this.keys = [identity.accounts[0].publicKey];
                this.accountName = identity.accounts[0].name;
            }
            catch (e) {
                throw new UALScatterError_1.UALScatterError('Unable load user\'s identity', universal_authenticator_library_1.UALErrorType.DataRequest, e);
            }
        });
    }
    authenticate(challenge, resolve) {
        this.scatter.authenticate(challenge).then((signature) => __awaiter(this, void 0, void 0, function* () {
            const publicKey = this.getPublicKey(challenge, signature);
            const myKeys = yield this.getKeys();
            let resolvedValue = false;
            for (const key of myKeys) {
                if (key === publicKey) {
                    resolvedValue = true;
                }
            }
            resolve(resolvedValue);
        }));
    }
    getPublicKey(challenge, signature) {
        const ellipticSignature = eosjs_jssig_1.Signature.fromString(signature).toElliptic();
        const ellipticHashedStringAsBuffer = Buffer.from(ec.hash().update(challenge).digest(), 'hex');
        const ellipticRecoveredPublicKey = ec.recoverPubKey(ellipticHashedStringAsBuffer, ellipticSignature, ellipticSignature.recoveryParam, 'hex');
        const ellipticPublicKey = ec.keyFromPublic(ellipticRecoveredPublicKey);
        return eosjs_jssig_1.PublicKey.fromElliptic(ellipticPublicKey, KeyType.k1).toString();
    }
}
exports.ScatterUser = ScatterUser;
