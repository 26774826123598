import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import Constants from "../Constants";
import StorageHelper from "../helpers/StorageHelper";
import "./Spins.css";
import { StringInput, FloatInput, IntegerInput } from "./generic/Inputs";
import { Button, Tooltip } from "@mui/material";
import FormDialog from "./generic/FormDialog";

import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import StripedDataGrid from "./generic/StripedDataGrid";

export default function Loots() {
  const defaultCollection = Constants.DEFAULT_COLLECTION;
  const defaultSchemaName = "";
  const defaultTemplateName = "";
  const defaultTemplateId = 0;
  const defaultRemaining = -1;
  const defaultAbundance = 0.1;

  const [loading, setLoading] = useState(true);
  const [fetchedLoots, setFetchedLoots] = useState([]);
  const [loots, setLoots] = useState([]);
  const [modalOn, setModalOn] = useState(false);
  const [modalTitle, setModalTitle] = useState("Create Loot");
  const [saveSuccessMessage, setSaveSuccessMessage] = useState(
    "Loot created successfully"
  );

  //inputs
  const [collectionName, setCollectionName] = useState(defaultCollection);
  const [schemaName, setSchemaName] = useState(defaultSchemaName);
  const [templateName, setTemplateName] = useState(defaultTemplateName);
  const [templateId, setTemplateId] = useState(defaultTemplateId);
  const [abundance, setAbundance] = useState(defaultAbundance);
  const [remaining, setRemaining] = useState(defaultRemaining);

  async function fetchData() {
    const payload = {
      filter: {},
    };
    console.log("Constants.API_URL", Constants.API_URL);
    console.log("payload", payload);
    try {
      let res = await axios.get(Constants.API_URL + "/loots", payload);
      console.log("res", res);
      let data = res.data;
      console.log("data", data);
      setFetchedLoots([...data.loots.slice().reverse()]);
      setLoots(data.loots.slice().reverse());
      setLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const loadDefaults = async () => {
    setModalTitle("Create Loot");
    setSaveSuccessMessage("Loot created successfully");
    setCollectionName(defaultCollection);
    setSchemaName(defaultSchemaName);
    setTemplateName(defaultTemplateName);
    setTemplateId(defaultTemplateId);
    setRemaining(defaultRemaining);
    setAbundance(defaultAbundance);
  };

  const createNewLoot = () => {
    loadDefaults();
    setModalOn(true);
  };

  const updateLoot = (
    _templateName,
    _templateId,
    _schemaName,
    _collectionName,
    _abundance,
    _remaining
  ) => {
    console.log({
      _templateName,
      _templateId,
      _schemaName,
      _collectionName,
      _abundance,
      _remaining,
    });
    setModalTitle(`Update Loot #${_templateId} ${_templateName}`);
    setSaveSuccessMessage("Loot updated successfully");
    setCollectionName(_collectionName);
    setSchemaName(_schemaName);
    setTemplateName(_templateName);
    setTemplateId(_templateId);
    setRemaining(_remaining);
    setAbundance(_abundance);
    setModalOn(true);
  };

  const deleteLoot = async function (_id, index) {
    var authData = StorageHelper.getAuthData();
    const payload = {
      data: {
        username: authData.username,
        password: authData.password,
        id: _id,
      },
    };
    console.log("Constants.API_URL", Constants.API_URL);
    console.log("payload", payload);
    try {
      let res = await axios.delete(Constants.API_URL + "/loots", {
        ...payload,
      });
      console.log("res", res);
      res = res.data;
      if (res.success) {
        if (res.result.deletedCount > 0) {
          alert("Loot deleted successfully");

          //Update client loot data
          const fchdLoots = [...fetchedLoots];
          const oldLoots = [...loots];
          const fchdLootsIndex = oldLoots.findIndex((loot) => {
            const loot_id = loot._id.toString();
            return loot_id == _id;
          });
          fchdLoots.splice(fchdLootsIndex, 1);
          oldLoots.splice(index, 1);
          setFetchedLoots([...fchdLoots]);
          setLoots([...oldLoots]);
        } else {
          alert("No loot data was deleted");
        }
      }

      setLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  const saveLoot = async function () {
    if (
      templateId == "" ||
      templateName == "" ||
      collectionName == "" ||
      schemaName == ""
    ) {
      alert("Please fill out all fields");
      return;
    }
    var authData = StorageHelper.getAuthData();
    const payload = {
      username: authData.username,
      password: authData.password,
      template_id: templateId,
      template_name: templateName,
      collection_name: collectionName,
      schema_name: schemaName,
      abundance,
      remaining,
    };

    console.log("Constants.API_URL", Constants.API_URL);
    console.log("payload", payload);
    try {
      let res = await axios.post(Constants.API_URL + "/saveLoot", {
        ...payload,
      });
      console.log("res", res);
      res = res.data;
      if (res.success) {
        alert(saveSuccessMessage);
        fetchData();

        //Close modal if not create mode
        if (saveSuccessMessage != "Created Loot Successfully") {
          setModalOn(false);
        }
      } else {
        alert("Loot creation failed");
      }

      setLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  const tableCols = [
    { field: "id", headerName: "ID", width: 150 },
    { field: "template_name", headerName: "Template Name", width: 150 },
    { field: "template_id", headerName: "Template ID", width: 150 },
    { field: "schema_name", headerName: "Schema Name", width: 150 },
    { field: "collection_name", headerName: "Collection Name", width: 150 },
    { field: "abundance", headerName: "Abundance", width: 150 },
    { field: "remaining", headerName: "Remaining", width: 150 },
    {
      field: "controls",
      headerName: "Controls",
      width: 400,
      renderCell: (params) => (
        <>
          <Tooltip title="Edit">
            <Button
              variant="contained"
              color="warning"
              key={"edit_" + params.id}
              onClick={() => {
                updateLoot(
                  params.row.template_name,
                  params.row.template_id,
                  params.row.schema_name,
                  params.row.collection_name,
                  params.row.abundance,
                  params.row.remaining
                );
              }}
            >
              <EditIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              variant="contained"
              color="error"
              key={"delete_" + params.id}
              sx={{ ml: 2 }}
              onClick={() => {
                var confirmed = window.confirm(
                  "Are you sure you want to delete data for loot:" +
                    params.row.template_name +
                    "?"
                );
                if (confirmed) deleteLoot(params.id, params.row.index);
              }}
            >
              <DeleteIcon />
            </Button>
          </Tooltip>
        </>
      ),
    },
  ];

  const tableRows = useMemo(() => {
    return loots.map((_data, index) => {
      const id = _data._id.toString();
      console.log({ _data });
      return {
        ..._data,
        id,
        index,
      };
    });
  }, [loots]);

  return (
    <div style={loading ? { textAlign: "center" } : {}}>
      {/** StartModal */}
      <FormDialog
        style={{ width: "100%" }}
        open={modalOn}
        setOpen={setModalOn}
        title={modalTitle}
        isActive={modalOn}
        description=""
        onSubmit={saveLoot}
      >
        <StringInput
          id="template-name"
          label="Template Name"
          onValueChanged={setTemplateName}
          value={templateName}
          style={{
            width: "100%",
          }}
        />{" "}
        <br />
        <IntegerInput
          id="template-id"
          label="Template ID"
          onValueChanged={setTemplateId}
          value={templateId}
          style={{
            width: "100%",
          }}
        />{" "}
        <br />
        <StringInput
          id="collection-name"
          label="Collection Name"
          onValueChanged={setCollectionName}
          value={collectionName}
          style={{
            width: "100%",
          }}
        />{" "}
        <br />
        <StringInput
          id="schema-name"
          label="Schema Name"
          onValueChanged={setSchemaName}
          value={schemaName}
          style={{
            width: "100%",
          }}
        />{" "}
        <br />
        <FloatInput
          id="abundance"
          label="Abundance"
          onValueChanged={setAbundance}
          value={abundance}
          style={{
            width: "100%",
          }}
        />{" "}
        <br />
        <IntegerInput
          id="remaining"
          label="Remaining"
          onValueChanged={setRemaining}
          value={remaining}
          style={{
            width: "100%",
          }}
        />{" "}
        <br />
      </FormDialog>
      {/** EndModal */}

      {loading ? (
        <h3>Fetching data...</h3>
      ) : (
        <div>
          <Button
            sx={{
              ml: 2,
            }}
            variant="contained"
            color="info"
            startIcon={<AddIcon />}
            onClick={createNewLoot}
          >
            Create Loot
          </Button>

          <div style={{ height: "80vh", width: "100%" }}>
            <StripedDataGrid
              rows={tableRows}
              columns={tableCols}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
              }
            />
          </div>
        </div>
      )}
    </div>
  );
}
