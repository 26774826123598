import {
  Box,
  Card,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

const SimpleTable = ({ mainKey = "", controls, title, colData, rowData }) => {
  return (
    <Card>
      <CardHeader
        title={
          <>
            <Grid container>
              <Grid
                item
                sx={{
                  display: "flex",
                }}
                xs={12}
              >
                <Typography>{title}</Typography>
                {controls}
              </Grid>
            </Grid>
          </>
        }
      />
      <Box sx={{ minWidth: 800 }}>
        <Table>
          <TableHead>
            <TableRow>
              {colData.map((_data) => (
                <TableCell {..._data}>{_data?.content}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowData.map((_row) => (
              <TableRow hover key={`${mainKey}_${_row.key}`}>
                {_row.cols.map((_data, _index) => (
                  <TableCell key={`${mainKey}_${_row.key}_${_index}`}>
                    {_data.content}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Card>
  );
};

export default SimpleTable;
