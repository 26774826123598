import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Anchor } from "ual-anchor";
import { Scatter } from "ual-scatter";
import { UALProvider, withUAL } from "ual-reactjs-renderer";
import Constants from "./Constants.js";
const AppWithUAL = withUAL(App);
console.log({ EOS: Constants.EOS });

const anchor = new Anchor([Constants.EOS], {
  // Required: The app name, required by anchor-link. Short string identifying the app
  appName: "crashderbyio-admin",
  // Optional: a @greymass/eosio APIClient from eosjs for both your use and to use internally in UAL
  // client = new APIClient({ provider }),
  // Optional: a JsonRpc instance from eosjs for your use
  // rpc: new JsonRpc(),
  // Optional: The callback service URL to use, defaults to https://cb.anchor.link
  // service: 'https://cb.anchor.link',
  // Optional: A flag to disable the Greymass Fuel integration, defaults to false (enabled)
  // disableGreymassFuel: false,
  // Optional: An account name on a Fuel enabled network to specify as the referrer for transactions
  // fuelReferrer: 'teamgreymass',
  // Optional: A flag to enable the Anchor Link UI request status, defaults to true (enabled)
  // requestStatus: true,
  // Optional: Whether or not to verify the signatures during user login, defaults to false (disabled)
  // verifyProofs: false,
});

const scatter = new Scatter([Constants.EOS], {
  appName: "crashderbyio-admin",
});

ReactDOM.render(
  <React.StrictMode>
    <UALProvider chains={[Constants.EOS]} authenticators={[anchor, scatter]}>
      <AppWithUAL />
    </UALProvider>
    {/**
     *
     */}
  </React.StrictMode>,
  document.getElementById("root")
);
