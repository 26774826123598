export default {
  ual: null,
  convertActionToDefaultTransaction(actions) {
    return {
      actions,
      configurations: { blocksBehind: 3, expireSeconds: 30 },
    };
  },
  async SignTransaction(actions) {
    var transactionData = this.convertActionToDefaultTransaction(actions);
    console.log("debug-bnx-claim SignTransaction", { transactionData });
    try {
      var receipt = await this.ual.activeUser.signTransaction(
        {
          actions: transactionData.actions,
        },
        { ...transactionData.configurations }
      );
      console.log("debug-bnx-claim SignTransaction receipt", receipt);
      return true;
    } catch (error) {
      console.log("debug-bnx-claim SignTransaction failed with error:", error);
      return false;
    }
  },
  
  getTokenAmount(asset) {
    return asset.split(" ")[0];
  },
};
