import React, { useEffect, useState, useMemo } from "react";
import { DashboardCard } from "./DashboardCard";
import axios from "axios";
import { format } from "date-fns";
import {
  PointOfSale as PointOfSaleIcon,
  Lock as LockIcon,
  ArrowDownward as ArrowDownwardIcon,
  LocalFireDepartment as LocalFireDepartmentIcon,
  DriveEta as DriveEtaIcon,
  CurrencyBitcoin as CurrencyBitcoinIcon,
  CurrencyExchange as CurrencyExchangeIcon,
  AccountBalanceWallet as AccountBalanceWalletIcon,
  Stars as StarsIcon,
  SportsScore as SportsScoreIcon,
  Star as StarIcon,
  Savings as SavingsIcon,
} from "@mui/icons-material";
import {
  Box,
  Typography,
  Grid,
  Container,
  LinearProgress,
} from "@mui/material";
import Constants from "../../Constants";
import SimpleTable from "../../components/SimpleTable";
function Dashboard(props) {
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({});

  useEffect(() => {
    async function fetchData() {
      const payload = {};
      console.log("Constants.API_URL", Constants.API_URL);
      console.log("payload", payload);
      try {
        let res = await axios.get(Constants.API_URL + "/stats", payload);
        console.log("res", res);
        let data = res.data;
        console.log("data", data);
        setStats(data);
        setLoading(false);
      } catch (error) {
        console.log("error", error);
      }
    }

    fetchData();
  }, []);

  let distributionCols = [
    {
      content: "Name",
    },
    {
      content: "Distributed",
    },
    {
      content: "Latest Distribution",
    },
  ];

  let distributionRows = [];

  let totalDistributedTokens = 0;

  let sponsorCols = [
    {
      content: "Name",
    },
    {
      content: "Tokens Bought",
    },
    {
      content: "Tokens Distributed",
    },
    {
      content: "NFTs Minted",
    },
    {
      content: "NFTs Burned",
    },
  ];

  let sponsorRows = [];
  let totalSponsoredTokens = 0;

  if (!loading) {
    distributionRows = stats.distributions.map((_dist) => {
      const distributed = _dist.balance;
      totalDistributedTokens += distributed;

      return {
        key: _dist.distributionKey,
        cols: [
          {
            content: _dist.title,
          },
          {
            content: _dist.balance,
          },
          {
            content:
              _dist.distribution_logs.length > 0
                ? format(
                    _dist.distribution_logs[_dist.distribution_logs.length - 1]
                      ?.log_date,
                    "dd/MM/yyyy"
                  )
                : "No allocations yet",
          },
        ],
      };
    });

    console.table(distributionRows);

    sponsorRows = stats.sponsors.map((_sponsor) => {
      const sponsored = _sponsor.sponsorships.reduce(
        (totalTokens, _sponsorship) => {
          console.log({ _sponsorship, totalTokens });
          return (
            totalTokens + _sponsorship.token_value * _sponsorship.nfts_minted
          );
        },
        0
      );
      const distributed = _sponsor.sponsorships.reduce(
        (totalTokens, _sponsorship) => {
          console.log({ _sponsorship, totalTokens });
          return (
            totalTokens + _sponsorship.token_value * _sponsorship.nfts_burned
          );
        },
        0
      );
      totalSponsoredTokens += sponsored;

      return {
        key: _sponsor._id,
        cols: [
          {
            content: _sponsor.name,
          },
          {
            content: sponsored,
          },
          {
            content: distributed,
          },
          {
            content: _sponsor.sponsorships.reduce(
              (totalTokens, _sponsorship) => {
                console.log({ _sponsorship, totalTokens });
                return totalTokens + _sponsorship.nfts_minted;
              },
              0
            ),
          },
          {
            content: _sponsor.sponsorships.reduce(
              (totalTokens, _sponsorship) => {
                console.log({ _sponsorship, totalTokens });
                return totalTokens + _sponsorship.nfts_burned;
              },
              0
            ),
          },
        ],
      };
    });
  }

  return (
    <>
      {loading ? (
        <h3>Fetching data...</h3>
      ) : (
        <>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              py: 8,
            }}
          >
            <Container maxWidth={false}>
              <Grid container spacing={3}>
                <Grid item lg={4} sm={6} xs={12}>
                  <DashboardCard
                    title="Total Battles"
                    stats={stats.totalBattles}
                    icon={<DriveEtaIcon />}
                    iconBg="success"
                  >
                    <SportsScoreIcon color="success" />
                    <Typography
                      color="info"
                      sx={{
                        mr: 1,
                      }}
                      variant="body2"
                    >
                      12
                    </Typography>
                    <Typography color="textSecondary" variant="caption">
                      Battles this month
                    </Typography>
                  </DashboardCard>
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                  <DashboardCard
                    title="Total Tokens Minted"
                    stats={stats.minted}
                    icon={<CurrencyBitcoinIcon />}
                    iconBg="warning"
                  >
                    <Box sx={{ width: "100%" }}>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            pb: 1,
                            mb: 0,
                          }}
                        >
                          <CurrencyExchangeIcon color="success" />
                          <Typography
                            color="success"
                            sx={{
                              ml: 1,
                              mr: 1,
                            }}
                            variant="body2"
                          >
                            {(stats.minted / stats.maxSupply) * 100}%
                          </Typography>
                          <Typography color="textSecondary" variant="caption">
                            Minted
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <LinearProgress
                            value={(stats.minted / stats.maxSupply) * 100}
                            variant="determinate"
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </DashboardCard>
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                  <DashboardCard
                    title="Total In Wallet"
                    stats={stats.tokenBalance}
                    icon={<AccountBalanceWalletIcon />}
                  >
                    <CurrencyExchangeIcon color="info" />
                    <Typography
                      color="info"
                      sx={{
                        ml: 1,
                        mr: 1,
                      }}
                      variant="body2"
                    >
                      {stats.circulatingSupply}
                    </Typography>
                    <Typography color="textSecondary" variant="caption">
                      Circulating
                    </Typography>
                  </DashboardCard>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <DashboardCard
                    title="Total Rewarded"
                    stats={
                      (parseFloat(stats.totalAwardedInBattles) + parseFloat(stats.totalAwardedInStaking)).toFixed(4)
                    }
                    icon={<StarsIcon />}
                    iconBg="warning"
                  >
                    <StarIcon color="warning" />
                    <Typography
                      color="warning"
                      sx={{
                        mr: 1,
                      }}
                      variant="body2"
                    >
                      {stats.totalAwardedInBattles}
                    </Typography>
                    <Typography
                      sx={{
                        mr: 2,
                      }}
                      color="textSecondary"
                      variant="caption"
                    >
                      Battle Rewards
                    </Typography>{" "}
                    <SavingsIcon
                      sx={{
                        ml: 2,
                      }}
                      color="warning"
                    />
                    <Typography
                      color="warning"
                      sx={{
                        ml: 1,
                        mr: 1,
                      }}
                      variant="body2"
                    >
                      {stats.totalAwardedInStaking}
                    </Typography>
                    <Typography color="textSecondary" variant="caption">
                      Staking Rewards
                    </Typography>
                  </DashboardCard>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <DashboardCard
                    title="Total Staked"
                    stats={stats.totalStaked}
                    icon={<LockIcon />}
                    iconBg="warning"
                  >
                    <StarIcon color="warning" />
                    <Typography
                      color="warning"
                      sx={{
                        mr: 1,
                      }}
                      variant="body2"
                    >
                      {stats.totalAwardedInStaking}
                    </Typography>
                    <Typography
                      sx={{
                        mr: 2,
                      }}
                      color="textSecondary"
                      variant="caption"
                    >
                      Total Rewarded
                    </Typography>{" "}
                    <PointOfSaleIcon
                      sx={{
                        ml: 2,
                      }}
                      color="warning"
                    />
                    <Typography
                      color="warning"
                      sx={{
                        ml: 1,
                        mr: 1,
                      }}
                      variant="body2"
                    >
                      {stats.dailyRewards}
                    </Typography>
                    <Typography color="textSecondary" variant="caption">
                      Daily Rewards
                    </Typography>
                  </DashboardCard>
                </Grid>
                <Grid item xs={9}></Grid>
                <Grid item xs={6}>
                  <SimpleTable
                    mainKey="distributions"
                    title={`Distributions - Total Tokens: ${totalDistributedTokens}`}
                    colData={distributionCols}
                    rowData={distributionRows}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SimpleTable
                    mainKey="sponsorships"
                    title={`Sponsorships - Total Tokens: ${totalSponsoredTokens}`}
                    colData={sponsorCols}
                    rowData={sponsorRows}
                  />
                </Grid>
                <Grid item lg={4} md={6} xl={3} xs={12}></Grid>
                <Grid item lg={8} md={12} xl={9} xs={12}></Grid>
              </Grid>
            </Container>
          </Box>
        </>
      )}
    </>
  );
}

export default Dashboard;
