import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import Constants from "../Constants";
import StorageHelper from "../helpers/StorageHelper";
import "./Spins.css";
import { Button, Link, Tooltip, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  Delete as DeleteIcon,
  OpenInNew as OpenInNewIcon,
} from "@mui/icons-material";
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { StringInput } from "./generic/Inputs";
import FormDialog from "./generic/FormDialog";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

export default function Players() {
  const [loading, setLoading] = useState(true);
  const [fetchedPlayers, setFetchedPlayers] = useState([]);
  const [players, setPlayers] = useState([]);

  const [modalOn, setModalOn] = useState(false);
  const [selectedPlayerName, setSelectedPlayerName] = useState("");
  const [newPlayerState, setNewPlayerState] = useState("{}");

  useEffect(() => {
    async function fetchData() {
      const payload = {
        filter: {},
      };
      console.log("Constants.API_URL", Constants.API_URL);
      console.log("payload", payload);
      try {
        let res = await axios.get(Constants.API_URL + "/players", payload);
        console.log("res", res);
        let data = res.data;
        console.log("data", data);
        setFetchedPlayers([...data.players.slice().reverse()]);
        setPlayers(data.players.slice().reverse());
        setLoading(false);
      } catch (error) {
        console.log("error", error);
      }
    }

    fetchData();
  }, []);

  const deletePlayer = async function (_id, player_name, index) {
    const payload = {
      data: {
        filter: {
          _id,
          player_name,
        },
      },
    };
    console.log("Constants.API_URL", Constants.API_URL);
    console.log("payload", payload);
    try {
      let res = await axios.delete(Constants.API_URL + "/players", {
        ...payload,
      });
      console.log("res", res);
      res = res.data;
      if (res.success) {
        if (res.result.deletedCount > 0) {
          alert("Player deleted successfully");

          //Update client player data
          const fchdPlayers = [...fetchedPlayers];
          const oldPlayers = [...players];
          const fchdPlayersIndex = oldPlayers.findIndex((player) => {
            const player_id = player._id.toString();
            return player_id == _id;
          });
          fchdPlayers.splice(fchdPlayersIndex, 1);
          oldPlayers.splice(index, 1);
          setFetchedPlayers([...fchdPlayers]);
          setPlayers([...oldPlayers]);
        } else {
          alert("No player data was deleted");
        }
      }

      setLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  };


  const setPlayerState = async function (player_name, newState) {
    const payload = {
      player_name,
      newState
    };
    console.log("Constants.API_URL", Constants.API_URL);
    console.log("payload", payload);
    try {
      let res = await axios.post(Constants.API_URL + "/playerstate", {
        ...payload,
      });
      console.log("res", res);
      res = res.data;
      if (res.success) {
        console.log({res});
      }

      setLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  };
  
  const viewPlayerState = async function (player_name) {
    console.log("Constants.API_URL", Constants.API_URL);
    try {
      let res = await axios.get(Constants.API_URL + "/playerstate?player_name="+player_name)
      console.log("res", res);
      res = res.data;
      if (res.success) {
        console.log({res});
      }

      setLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  };
  const tableCols = [
    { field: "id", headerName: "ID", width: 150 },
    { field: "player", headerName: "Player", width: 150 },
    { field: "derby", headerName: "Claimable Derby", width: 150 },
    { field: "nfts", headerName: "Claimable NFTs", width: 150 },
    { field: "state", headerName: "State", width: 75 },
    { field: "consumedEne", headerName: "Consumed ⚡", width: 75 },
    { field: "consumedDur", headerName: "Consumed 🔧", width: 75 },
    { field: "joinDate", headerName: "Join Date", width: 250 },
    {
      field: "controls",
      headerName: "Controls",
      width: 400,
      renderCell: (params) => (
        <>
          <Tooltip title="View in Explorer">
            <Button
              variant="contained"
              color="info"
              onClick={() => {
                window.open(
                  `https://wax-test.bloks.io/account/${Constants.CONTRACT_ACCOUNT}?loadContract=true&tab=Tables&table=${Constants.ACCOUNTS_TABLE}&account=${Constants.CONTRACT_ACCOUNT}&scope=${Constants.CONTRACT_ACCOUNT}&limit=1&lower_bound=${params.row.player}`,
                  "_blank"
                );
              }}
              sx={{ ml: 1 }}
            >
              <OpenInNewIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Log Player State in console">
            <Button
              variant="contained"
              color="info"
              onClick={() => {
                viewPlayerState(params.row.player);
              }}
              sx={{ ml: 1 }}
            >
              <PersonSearchIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Force Player State">
            <Button
              variant="contained"
              color="warning"
              onClick={() => {
                setSelectedPlayerName(params.row.player);
                setModalOn(true);
              }}
              sx={{ ml: 1 }}
            >
              <ManageAccountsIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              variant="contained"
              color="error"
              key={"delete_" + params.id}
              onClick={() => {
                console.log({ params });
                var confirmed = window.confirm(
                  "Are you sure you want to delete data for player:" +
                    params.row.player +
                    "?"
                );
                if (confirmed)
                  deletePlayer(params.id, params.row.player, params.row.index);
              }}
              sx={{ ml: 1 }}
            >
              <DeleteIcon />
            </Button>
          </Tooltip>
        </>
      ),
    },
  ];

  const tableRows = useMemo(() => {
    return players.map((playerData, index) => {
      const player_id = playerData._id.toString();
      let claimable_nfts = [];
      claimable_nfts = playerData.claimable_nfts.map((nft) => {
        return nft.template_name;
      });

      return {
        id: player_id,
        player: playerData.player_name,
        derby: playerData.claimable_derby,
        nfts: claimable_nfts.join(", "),
        state: playerData?.flag?.state,
        consumedEne: playerData?.energy?.consumedStamina,
        consumedDur: playerData?.energy?.consumedDurability,
        joinDate: playerData.join_date
          ? new Date(playerData.join_date).toUTCString()
          : "",
        index,
      };
    });
  }, [players]);


  return (
    <div style={loading ? { textAlign: "center" } : {}}>
      {/** StartModal */}
      <FormDialog
        style={{ width: "100%" }}
        open={modalOn}
        setOpen={setModalOn}
        title={"Update Player State"}
        isActive={modalOn}
        description=""
        onSubmit={async () => {
          await setPlayerState(selectedPlayerName, JSON.parse(newPlayerState));
          setSelectedPlayerName("");
          setNewPlayerState("{}");
        }}
      >
        <StringInput
          id="selectedPlayerName"
          label="Player"
          onValueChanged={setSelectedPlayerName}
          value={selectedPlayerName}
          style={{
            width: "100%",
          }}
        />{" "}
        <StringInput
          id="newPlayerState"
          label="New Player State"
          onValueChanged={setNewPlayerState}
          value={newPlayerState}
          placeholder={"Input state as stringified JSON"}
          rows={5}
          style={{
            width: "100%",
          }}
        />{" "}

        <Typography component="small">This will update only the fields specified in the JSON.</Typography>
      </FormDialog>
      {/** EndModal */}

      {loading ? (
        <h3>Fetching data...</h3>
      ) : (
        <div>
          {/**
          <Button color="error" variant="contained" onClick={clearSort}>
            Clear Sorting
          </Button>{" "} */}
          <div style={{ height: "80vh", width: "100%", overflow: "auto" }}>
            <DataGrid rows={tableRows} columns={tableCols} />
          </div>
        </div>
      )}
    </div>
  );
}
