const Constants = {};
Constants.BASE_URL = "/admin-ui/";
Constants.API_URL = "http://stage.crashderby.io:2567";
Constants.CONFIG_KEYS = [
  { key: "MAINTENANCE", type: "toggle" },
  { key: "REWARD_POOL", type: "integer" },
  { key: "NFT_REWARD", type: "integer" },
  { key: "CHARISMA_DIVISOR", type: "integer" },
  { key: "TROPHY_COLLECTION", type: "string" },
  { key: "TROPHY_TEMPLATE_ID", type: "string" },
  { key: "STAMINA_PER_GAME", type: "integer" },
  { key: "DURABILITY_PER_GAME", type: "integer" },
  { key: "OPEN_COOLDOWN", type: "integer" },
  { key: "SEASON", type: "string" },
];

Constants.EOS = {
  chainId: "f16b1833c747c43682f4386fca9cbb327929334a762755ebec17f6f23c9b8a12",
  rpcEndpoints: [
    {
      protocol: "https",
      host: "testnet.wax.pink.gg",
      port: "443",
    },
    {
      protocol: "https",
      host: "wax-test.eosdac.io",
      port: "443",
    },
    // {
    //   protocol: "https",
    //   host: "waxtestnet.greymass.com",
    //   port: "443",
    // }
    //"https://waxtestnet.greymass.com", greymass is down,
  ],
};

Constants.CONTRACT_ACCOUNT = "wxdrbycntrct";
Constants.ACCOUNTS_TABLE = "accounts5";
Constants.TOKEN_SYMBOL = "DERBY";
Constants.DEFAULT_COLLECTION = "crashemtest3";
Constants.ATOMICASSETS = "atomicassets";
Constants.STARTER_PACK_ID = 452017;

if (process.env.BUILD_FOR == "LOCAL") {
  Constants.CONTRACT_ACCOUNT = "wxdrbylocal2";
  Constants.API_URL = "http://localhost:2567/admin";
}

if (process.env.BUILD_FOR == "STAGE") {
  Constants.CONTRACT_ACCOUNT = "wxdrbystage1";
  Constants.API_URL = "http://stage.crashderby.io:2567/admin";
}

if (process.env.BUILD_FOR == "community") {
  Constants.CONTRACT_ACCOUNT = "wxdrbycntrct";
  Constants.API_URL = "https://api.crashderby.io/admin"; //test runner
}

Constants.MARKET_CONTRACT = "wxdrbymarkt1";

if (process.env.BUILD_FOR == "LOCAL") {
  Constants.MARKET_CONTRACT = "wxdrbymarkt1";
}

if (process.env.BUILD_FOR == "STAGE") {
  Constants.MARKET_CONTRACT = "wxdrbymarkt2";
}

if (process.env.BUILD_FOR == "community") {
  Constants.MARKET_CONTRACT = "wxdrbymarkt4";
}

//Allow values only on local or stage builds
if (process.env.BUILD_FOR == "LOCAL" || process.env.BUILD_FOR == "STAGE") {
  Constants.CONFIG_KEYS.push({ key: "DUMMIES_ENABLED", type: "toggle" });
  Constants.CONFIG_KEYS.push({ key: "DUMMIES_SPAWNED", type: "integer" });
}

Constants.LOCAL_STORAGE_KEY = "crashemadmin_";

Constants.APP_NAME = "Crashderby.IO";

Constants.DERBY_MAX_SUPPLY = 10000000000000;

export default Constants;
