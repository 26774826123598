import React, { useEffect, useState, useMemo } from "react";
import { DashboardCard } from "../Dashboard/DashboardCard";
import axios from "axios";
import { format } from "date-fns";
import {
  PointOfSale as PointOfSaleIcon,
  Lock as LockIcon,
  ArrowDownward as ArrowDownwardIcon,
  LocalFireDepartment as LocalFireDepartmentIcon,
  DriveEta as DriveEtaIcon,
  CurrencyBitcoin as CurrencyBitcoinIcon,
  CurrencyExchange as CurrencyExchangeIcon,
  AccountBalanceWallet as AccountBalanceWalletIcon,
  Stars as StarsIcon,
  SportsScore as SportsScoreIcon,
  Star as StarIcon,
  Savings as SavingsIcon,
} from "@mui/icons-material";
import {
  Box,
  Typography,
  Grid,
  Container,
  LinearProgress,
} from "@mui/material";
import Constants from "../../Constants";
import SimpleTable from "../../components/SimpleTable";
import { Doughnut } from "react-chartjs-2";
import BarChart from "./BarChart";
import PieChart from "./PieChart";
import PieChartSample from "./PieChartSample";

const CardStatsTable = ({ data, type, totalMatches }) => {
  console.log("CardStatsTable", { data });

  let cols = [
    {
      content: "Key",
    },
    {
      content: "Rounds",
    },
    {
      content: "Wins",
    },
    {
      content: "Win rate",
    },
    {
      content: "Raw Win rate",
    },
  ];

  let rows = data.map((_card) => {
    return {
      key: _card._id,
      cols: [
        {
          content: _card.key,
        },
        {
          content: _card.rounds,
        },
        {
          content: _card.wins,
        },
        {
          content: `${(_card.wins / _card.rounds) * 100}%`,
        },
        {
          content: `${(_card.wins / totalMatches) * 100}%`,
        },
      ],
    };
  });

  return (
    <SimpleTable
      mainKey={`${type}DataTable`}
      title={`${type} Stats`}
      colData={cols}
      rowData={rows}
    />
  );
};

function RoundStats(props) {
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({});
  const [cardData, setCardData] = useState([]);
  const [cardPlacements, setCardPlacements] = useState([]);
  const [statsData, setStatsData] = useState([]);
  const [statsPlacements, setStatsPlacements] = useState([]);

  const [carData, setCarData] = useState([]);
  const [carChartData, setCarChartData] = useState([]);

  const [driverData, setDriverData] = useState([]);
  const [driverChartData, setDriverChartData] = useState([]);

  const [upgradeData, setUpgradeData] = useState([]);
  const [upgradeChartData, setUpgradeChartData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const payload = {};
      console.log("Constants.API_URL", Constants.API_URL);
      console.log("payload", payload);
      try {
        let res = await axios.get(
          Constants.API_URL + "/getCardRoundStats",
          payload
        );
        console.log("res", res);
        let data = res.data;
        console.log("data", data);
        setStats(data);
        setCardData(data.cardStats);
        setStatsData(data.statsPlacements);
        setCardPlacements(data.cardStats.placements);
        setStatsPlacements(data.statsPlacements.placements);
        const _carStats = data.cardStats.filter((_card) => _card.type == "car");
        setCarData(_carStats);
        const _driverStats = data.cardStats.filter(
          (_card) => _card.type == "driver"
        );
        setDriverData(_driverStats);
        const _upgradeStats = data.cardStats.filter(
          (_card) => _card.type == "upgrade"
        );
        setUpgradeData(_upgradeStats);
        setLoading(false);

        /**
         * 
        const _carChartData = {
          labels: _carStats.map((car) => car.key),
          datasets: [
            {
              label: "Wins",
              data: _carStats.map((car) => car.wins),
              backgroundColor: [
                "#ffbb11",
                "#ecf0f1",
              ],
            },
          ],
        }

        console.log({_carChartData});
        setCarChartData(_carChartData);
         */
      } catch (error) {
        console.log("error", error);
      }
    }

    fetchData();
  }, []);

  let statsDataCols = [
    {
      content: "Key",
    },
    {
      content: "🛡️",
    },
    {
      content: "🏆",
    },
    {
      content: "⚔️",
    },
    {
      content: "Rounds",
    },
    {
      content: "Wins",
    },
    {
      content: "Win rate",
    },
    {
      content: "Raw Win rate",
    },
  ];

  let statsDataRows = [];

  if (!loading) {
    statsDataRows = statsData.map((_data) => {
      const _stats = _data.key.split("|");
      const durability = _stats[0];
      const skill = _stats[1];
      const damage = _stats[2];

      return {
        key: _data._id,
        cols: [
          {
            content: _data.key,
          },
          {
            content: durability,
          },
          {
            content: skill,
          },
          {
            content: damage,
          },
          {
            content: _data.rounds,
          },
          {
            content: _data.wins,
          },
          {
            content: `${(_data.wins / _data.rounds) * 100}%`,
          },
          {
            content: `${(_data.wins / stats.matches.value) * 100}%`,
          },
        ],
      };
    });
  }

  return (
    <>
      {loading ? (
        <h3>Fetching data...</h3>
      ) : (
        <>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              py: 8,
            }}
          >
            <Container maxWidth={false}>
              <Grid container spacing={3}>
                <Grid item lg={4} sm={6} xs={12}>
                  <DashboardCard
                    title="Total Battles"
                    stats={stats.matches.value}
                    icon={<SportsScoreIcon />}
                    iconBg="success"
                  ></DashboardCard>
                </Grid>
                <Grid item xs={8}></Grid>
                <Grid item xs={6}>
                  {!loading && (
                    <CardStatsTable
                      data={carData}
                      type="Cars"
                      totalMatches={stats.matches.value}
                    />
                  )}
                </Grid>
                <Grid item xs={3}>
                  <PieChart
                    title={"Car Rounds"}
                    labels={carData.map((_car) => _car.key)}
                    dataset={carData.map((_car) => _car.rounds)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <PieChart
                    title={"Car Wins"}
                    labels={carData.map((_car) => _car.key)}
                    dataset={carData.map((_car) => _car.wins)}
                  />
                </Grid>
                <Grid item xs={6}>
                  {!loading && (
                    <CardStatsTable
                      data={driverData}
                      type="Drivers"
                      totalMatches={stats.matches.value}
                    />
                  )}
                </Grid>
                <Grid item xs={3}>
                  <PieChart
                    title={"Driver Rounds"}
                    labels={driverData.map((_driver) => _driver.key)}
                    dataset={driverData.map((_driver) => _driver.rounds)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <PieChart
                    title={"Driver Wins"}
                    labels={driverData.map((_driver) => _driver.key)}
                    dataset={driverData.map((_driver) => _driver.wins)}
                  />
                </Grid>
                <Grid item xs={6}>
                  {!loading && (
                    <CardStatsTable
                      data={upgradeData}
                      type="Upgrades"
                      totalMatches={stats.matches.value}
                    />
                  )}
                </Grid>
                <Grid item xs={3}>
                  <PieChart
                    title={"Upgrade Rounds"}
                    labels={upgradeData.map((_upgrade) => _upgrade.key)}
                    dataset={upgradeData.map((_upgrade) => _upgrade.rounds)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <PieChart
                    title={"Upgrade Wins"}
                    labels={upgradeData.map((_upgrade) => _upgrade.key)}
                    dataset={upgradeData.map((_upgrade) => _upgrade.wins)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SimpleTable
                    mainKey="statsData"
                    title={`Stats Data`}
                    colData={statsDataCols}
                    rowData={statsDataRows}
                  />
                </Grid>
                
                <Grid item xs={3}>
                  <PieChart
                    title={"Stat Rounds"}
                    labels={statsData.map((_stat) => {
                      const _statData = _stat.key.split("|");

                      return `${_statData[0]}🛡️ ${_statData[1]}🏆 ${_statData[2]}⚔️`
                    })}
                    dataset={statsData.map((_stat) => _stat.rounds)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <PieChart
                    title={"Stat Wins"}labels={statsData.map((_stat) => {
                      const _statData = _stat.key.split("|");

                      return `${_statData[0]}🛡️ ${_statData[1]}🏆 ${_statData[2]}⚔️`
                    })}
                    dataset={statsData.map((_stat) => _stat.wins)}
                  />
                </Grid>
              </Grid>
            </Container>
          </Box>
        </>
      )}
    </>
  );
}

export default RoundStats;
