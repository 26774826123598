import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import _toPropertyKey from "@babel/runtime/helpers/esm/toPropertyKey";
import * as React from 'react';
import { useGridApiMethod } from '../../utils/useGridApiMethod';
import { GridEvents } from '../../../models/events';
export const GRID_DEFAULT_STRATEGY = 'none';
export const GRID_STRATEGIES_PROCESSORS = {
  rowTreeCreation: 'rowTree',
  filtering: 'rowTree',
  sorting: 'rowTree'
};

/**
 * Implements a variant of the Strategy Pattern (see https://en.wikipedia.org/wiki/Strategy_pattern)
 *
 * More information and detailed example in (TODO add link to technical doc when ready)
 *
 * Some plugins contains custom logic that must only be applied if the right strategy is active.
 * For instance, the row grouping plugin has a custom filtering algorithm.
 * This algorithm must be applied by the filtering plugin if the row grouping is the current way of grouping rows,
 * but not if the tree data is the current way of grouping rows.
 *
 * =====================================================================================================================
 *
 * The plugin containing the custom logic must use:
 *
 * - `useGridRegisterStrategyProcessor` to register their processor.
 *   When the processor of the active strategy changes, it will fire `GridEvents.activeStrategyProcessorChange` to re-apply the processor.
 *
 * - `apiRef.current.unstable_setStrategyAvailability` to tell if their strategy can be used.
 *
 * =====================================================================================================================
 *
 * The plugin or component that needs to apply the custom logic of the current strategy must use:
 *
 * - `apiRef.current.unstable_applyStrategyProcessor` to run the processor of the active strategy for a given processor name.
 *
 * - `GridEvents.strategyAvailabilityChange` to update something when the active strategy changes.
 *    Warning: Be careful not to apply the processor several times.
 *    For instance `GridEvents.rowsSet` is fired by `useGridRows` whenever the active strategy changes.
 *    So listening to both would most likely run your logic twice.
 *
 * - `GridEvents.activeStrategyProcessorChange` to update something when the processor of the active strategy changes.
 *
 * =====================================================================================================================
 *
 * Each processor name is part of a strategy group which can only have one active strategy at the time.
 * For now, there is only one strategy group named `rowTree` which customize
 * - row tree creation algorithm.
 * - sorting algorithm.
 * - filtering algorithm.
 */
export const useGridStrategyProcessing = apiRef => {
  const availableStrategies = React.useRef(new Map());
  const strategiesCache = React.useRef({});
  const registerStrategyProcessor = React.useCallback((strategyName, processorName, processor) => {
    const cleanup = () => {
      const _ref = strategiesCache.current[processorName],
            otherProcessors = _objectWithoutPropertiesLoose(_ref, [strategyName].map(_toPropertyKey));

      strategiesCache.current[processorName] = otherProcessors;
    };

    if (!strategiesCache.current[processorName]) {
      strategiesCache.current[processorName] = {};
    }

    const groupPreProcessors = strategiesCache.current[processorName];
    const previousProcessor = groupPreProcessors[strategyName];
    groupPreProcessors[strategyName] = processor;

    if (!previousProcessor || previousProcessor === processor) {
      return cleanup;
    }

    if (strategyName === apiRef.current.unstable_getActiveStrategy(GRID_STRATEGIES_PROCESSORS[processorName])) {
      apiRef.current.publishEvent(GridEvents.activeStrategyProcessorChange, processorName);
    }

    return cleanup;
  }, [apiRef]);
  const applyStrategyProcessor = React.useCallback((processorName, params) => {
    const activeStrategy = apiRef.current.unstable_getActiveStrategy(GRID_STRATEGIES_PROCESSORS[processorName]);

    if (activeStrategy == null) {
      throw new Error("Can't apply a strategy processor before defining an active strategy");
    }

    const groupCache = strategiesCache.current[processorName];

    if (!groupCache || !groupCache[activeStrategy]) {
      throw new Error(`No processor found for processor "${processorName}" on strategy "${activeStrategy}"`);
    }

    const processor = groupCache[activeStrategy];
    return processor(params);
  }, [apiRef]);
  const getActiveStrategy = React.useCallback(strategyGroup => {
    var _availableStrategyEnt;

    const strategyEntries = Array.from(availableStrategies.current.entries());
    const availableStrategyEntry = strategyEntries.find(([, strategy]) => {
      if (strategy.group !== strategyGroup) {
        return false;
      }

      return strategy.isAvailable();
    });
    return (_availableStrategyEnt = availableStrategyEntry == null ? void 0 : availableStrategyEntry[0]) != null ? _availableStrategyEnt : GRID_DEFAULT_STRATEGY;
  }, []);
  const setStrategyAvailability = React.useCallback((strategyGroup, strategyName, isAvailable) => {
    availableStrategies.current.set(strategyName, {
      group: strategyGroup,
      isAvailable
    });
    apiRef.current.publishEvent(GridEvents.strategyAvailabilityChange);
  }, [apiRef]);
  const strategyProcessingApi = {
    unstable_registerStrategyProcessor: registerStrategyProcessor,
    unstable_applyStrategyProcessor: applyStrategyProcessor,
    unstable_getActiveStrategy: getActiveStrategy,
    unstable_setStrategyAvailability: setStrategyAvailability
  };
  useGridApiMethod(apiRef, strategyProcessingApi, 'GridStrategyProcessing');
};