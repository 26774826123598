import { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { useRouter } from "../hooks/useRouter";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Divider,
  Drawer,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Logo from "../img/binx_gear_logo.png";
import { NavItem } from "./nav-item";
import Constants from "../Constants";
import StorageHelper from "../helpers/StorageHelper";
import {
  People as PeopleIcon,
  PieChart as PieChartIcon,
  EmojiEvents as EmojiEventsIcon,
  Inventory as InventoryIcon,
  LocalAtm as LocalAtmIcon,
  ShoppingCart as ShoppingCartIcon,
  Settings as SettingsIcon,
  Storefront as StorefrontIcon,
  Logout as LogoutIcon,
  AccountBalance as AccountBalanceIcon,
  Campaign as CampaignIcon,
  WbSunny as WbSunnyIcon,
  InsertChart as InsertChartIcon,
  PestControl as PestControlIcon
} from "@mui/icons-material";


const items = [
  {
    href: "/",
    icon: <PieChartIcon />,
    title: "Dashboard",
  },
  {
    href: "/roundStatistics",
    icon: <InsertChartIcon />,
    title: "Round Stats",
  },
  {
    href: "/players",
    icon: <PeopleIcon />,
    title: "Players",
  },
  {
    href: "/claims",
    icon: <InventoryIcon />,
    title: "Claims",
  },
  {
    href: "/opens",
    icon: <WbSunnyIcon />,
    title: "Opens",
  },
  {
    href: "/loots",
    icon: <EmojiEventsIcon />,
    title: "Loots",
  },
  {
    href: "/market",
    icon: <StorefrontIcon />,
    title: "Market",
  },
  {
    href: "/distributions",
    icon: <AccountBalanceIcon />,
    title: "Distributions",
  },
  {
    href: "/sponsors",
    icon: <CampaignIcon />,
    title: "Sponsors",
  },
  {
    href: "/configurations",
    icon: <SettingsIcon />,
    title: "Configurations",
  },
  {
    href: "/control",
    icon: <PestControlIcon />,
    title: "Control",
  },
  {
    href: "/airdrop",
    icon: <InventoryIcon />,
    title: "Airdrop",
  },
];

export const DashboardSidebar = (props) => {
  const { open, onClose } = props;
  const router = useRouter();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"), {
    defaultMatches: true,
    noSsr: false,
  });

  useEffect(
    () => {
      if (!router.isReady) {
        return;
      }

      if (open) {
        onClose?.();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router.asPath]
  );

  const content = (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div>
          <Box sx={{ px: 2, mt: 2 }}>
            <Box
              sx={{
                alignItems: "center",
                backgroundColor: "rgba(255, 255, 255, 0.04)",
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
                px: 3,
                py: "11px",
                borderRadius: 1,
              }}
            >
              <div>
                <Typography color="inherit" variant="subtitle1">
                  {Constants.APP_NAME}
                </Typography>
                <Typography color="neutral.400" variant="body2">
                  Admin
                </Typography>
                
              </div>
                <Box
                  component="img"
                  src={Logo}
                  sx={{
                    height: 42,
                  }}
                />
            </Box>
          </Box>
        </div>
        <Divider
          sx={{
            borderColor: "#2D3748",
            my: 3,
          }}
        />
        <Box sx={{ flexGrow: 1 }}>
          {items.map((item) => (
            <NavItem
              key={item.title}
              icon={item.icon}
              href={item.href}
              title={item.title}
            />
          ))}
        </Box>
        <Divider sx={{ borderColor: "#2D3748" }} />
        <Box
          sx={{
            px: 2,
            py: 3,
          }}
        >
          <NavLink to="https://material-kit-pro-react.devias.io/" passHref>
            <Button
              color="secondary"
              component="a"
              endIcon={<LogoutIcon />}
              fullWidth
              sx={{ mt: 2 }}
              variant="contained"
              onClick={() => {
                StorageHelper.clearAuthData();
                window.location.replace("/");
                delete axios.defaults.headers.common.username;
                delete axios.defaults.headers.common.password;
              }}
            >
              Logout
            </Button>
          </NavLink>
        </Box>
      </Box>
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "neutral.900",
            color: "#FFFFFF",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: "neutral.900",
          color: "#FFFFFF",
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
