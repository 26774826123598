import axios from "axios";
import React, { useEffect, useState } from "react";
import Constants from "../Constants";
import StorageHelper from "../helpers/StorageHelper";
import { Button, Tooltip, TextField } from "@mui/material";
import { Save as SaveIcon } from "@mui/icons-material";
import { StringInput, IntegerInput, FloatInput } from "./generic/Inputs";

//#region dynamic inputs
function ConfigSelection({ configKey, setConfigKey }) {
  console.log("debug-config-selection", { configKey });
  return (
    <select
      onChange={(e) => {
        setConfigKey(e.target.value);
      }}
    >
      {Constants.CONFIG_KEYS.map((_configKey, index) => {
        return (
          <option key={"config_keys_" + index} value={index}>
            {_configKey.key}
          </option>
        );
      })}
    </select>
  );
}

function BooleanInput({ type = "boolean", inputValue, onValueChanged }) {
  console.log("debug-1 inputValue", inputValue);
  let trueText = "True";
  let falseText = "False";

  if (type == "toggle") {
    trueText = "On";
    falseText = "Off";
  }

  return (
    <select
      id="bool_input"
      onChange={(e) => {
        console.log("Value changed");
        onValueChanged(e.target.value);
      }}
      value={inputValue}
    >
      <option value={true}>{trueText}</option>
      <option value={false}>{falseText}</option>
    </select>
  );
}

function DynamicConfigInput({ type, onValueChanged, inputValue }) {
  console.log("Render DynamicConfig", type);
 
  if (type == "string") {
    return (
      <StringInput
        id="Value"
        label="Value"
        onValueChanged={onValueChanged}
        value={inputValue}
      />
    );
  }

  if (type == "integer") {
    return (
      <IntegerInput
        id="Value"
        label="Value"
        onValueChanged={onValueChanged}
        value={inputValue}
      />
    );
  }

  if (type == "float") {
    return (
      <FloatInput
        id="Value"
        label="Value"
        onValueChanged={onValueChanged}
        value={inputValue}
      />
    );
  }

  if (type == "boolean" || type == "toggle") {
    console.log("debug-1 rendering boolean");
    return (
      <BooleanInput
        type={type}
        inputValue={inputValue}
        onValueChanged={onValueChanged}
      />
    );
  }
}
//#endregion

export default function Configurations() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [configSelected, setConfigSelected] = useState(null);
  const [configKey, setConfigKey] = useState("");
  const [configKeyIndex, setConfigKeyIndex] = useState(0);
  const [configValue, setConfigValue] = useState("");
  const [responseColor, setResponseColor] = useState("blue");
  const [responseMessage, setResponseMessage] = useState("");

  useEffect(() => {
    var authData = StorageHelper.getAuthData();
    setUsername(authData.username);
    setPassword(authData.password);
  }, []);

  //Update config value when setting new config key
  useEffect(() => {
    getConfig(configKey);
    setConfigSelected(Constants.CONFIG_KEYS[configKeyIndex]);
  }, [configKey]);

  //Update config value when setting new config key
  useEffect(() => {
    const selectedKey = Constants.CONFIG_KEYS[configKeyIndex].key;
    setConfigKey(selectedKey);
  }, [configKeyIndex]);

  const getTrueConfigValue = (confValue) => {
    var configType = Constants.CONFIG_KEYS[configKeyIndex].type;
    let _configValue = confValue;
    if (configType == "integer") {
      _configValue = parseInt(confValue);
    } else if (configType == "toggle" || configType == "boolean") {
      _configValue = confValue == "true" || confValue == true ? true : false;
    } else if (configType == "float") {
      _configValue = parseFloat(confValue);
    }

    return _configValue;
  };

  const getConfig = async (_configKey) => {
    try {
      let res = await axios.get(
        Constants.API_URL + "/config?config=" + _configKey
      );
      console.log("getConfig res", res);
      let responseData = res.data;

      if (responseData.success) {
        if (!responseData.config) {
          console.log("Config not found");
          setConfigValue("");
        } else {
          console.log("Config found", responseData);
          //Convert config value to appropriate type
          const confValue = getTrueConfigValue(responseData.config.value);
          setConfigValue(confValue);
        }
      } else {
        setResponseColor("red");
        setResponseMessage(data.error);
      }
    } catch (e) {
      console.log("error", e);
      setResponseColor("red");
      setResponseMessage("Cannot fetch config value");
    }
  };

  const saveConfig = async () => {
    const payload = {
      username,
      password,
      configKey,
      newState: getTrueConfigValue(configValue),
    };
    console.log("Constants.API_URL", Constants.API_URL);
    console.log("payload", payload);
    try {
      let res = await axios.post(Constants.API_URL + "/config", payload);
      console.log("res", res);
      let data = res.data;

      if (data.success) {
        setResponseColor("green");
        setResponseMessage("Configuration updated successfully.");
      } else {
        setResponseColor("red");
        setResponseMessage(data.error);
      }
    } catch (e) {
      console.log("error", e);
      setResponseColor("red");
      setResponseMessage("Configuration update failed");
    }
  };

  function setNewConfigIndex(newConfigIndex) {
    setConfigKeyIndex(newConfigIndex);
  }

  return (
    <div className="container">
      <span style={{ color: responseColor }}>{responseMessage}</span>
      <br />
      <br />

      <label>
        Config
        <ConfigSelection
          configKey={configKey}
          setConfigKey={(newConfigValue) => {
            setNewConfigIndex(newConfigValue);
          }}
        />
      </label>

      <br />
      <br />
      <label>
        {configSelected ? (
          <DynamicConfigInput
            type={configSelected.type}
            onValueChanged={setConfigValue}
            inputValue={configValue}
          />
        ) : (
          ""
        )}
      </label>

      <br />
      <br />
      <Tooltip title="Save Configuration">
        <Button
          variant="contained"
          color="success"
          startIcon={<SaveIcon />}
          onClick={() => {
            saveConfig();
          }}
        >
          Save Configuration
        </Button>
      </Tooltip>
      <br />
      <br />
    </div>
  );
}
