"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * This class provides the business logic layer for the UAL.
 */
class UAL {
    /**
     * @param chains          A list of chains the dapp supports.
     *
     * @param appName         The name of the app using the authenticators
     *
     * @param authenticators  A list of authenticator apps that the dapp supports.
     */
    constructor(chains, appName, authenticators) {
        this.chains = chains;
        this.appName = appName;
        this.authenticators = authenticators;
    }
    /**
     * Returns an object with a list of initialized Authenticators that returned true for shouldRender()
     * as well as an authenticator that supports autoLogin
     */
    getAuthenticators() {
        const availableAuthenticators = this.authenticators.filter((authenticator) => {
            return authenticator.shouldRender();
        });
        availableAuthenticators.forEach((authenticator) => authenticator.init());
        let autoLoginAuthenticator = null;
        if (availableAuthenticators.length === 1) {
            if (availableAuthenticators[0].shouldAutoLogin()) {
                autoLoginAuthenticator = availableAuthenticators[0];
            }
        }
        return {
            availableAuthenticators,
            autoLoginAuthenticator
        };
    }
}
exports.UAL = UAL;
