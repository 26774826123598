import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  HashRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import Login from "./pages/Login.jsx";
import Players from "./pages/Players.jsx";
import Claims from "./pages/Claims.jsx";
import Loots from "./pages/Loots.jsx";
import StorageHelper from "./helpers/StorageHelper";
import Configurations from "./pages/Configurations";
import Control from "./pages/Control";
import Dashboard from "./pages/Dashboard";
import { DashboardLayout } from "./layouts/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme.js";
import Market from "./pages/Market";
import Distributions from "./pages/Distributions.jsx";
import Sponsors from "./pages/Sponsors.jsx";
import Opens from "./pages/Opens.jsx";
import RoundStats from "./pages/RoundStats/index.jsx";
import Airdrop from "./pages/Airdrop/index.jsx";

// This site has 3 pages, all of which are rendered
// dynamically in the browser (not server rendered).
//
// Although the page does not ever refresh, notice how
// React Router keeps the URL up to date as you navigate
// through the site. This preserves the browser history,
// making sure things like the back button and bookmarks
// work properly.

function Test(props) {
  const location = useLocation();
  console.log("location", location);

  const protectedRoutes = ["/players", "/configurations", "/controls"];

  var inProtected = protectedRoutes.includes(location.pathname);
  if (!props.loggedIn) {
    //Check if in protected route and redirect to home
    console.log("inProtected", inProtected);
    if (inProtected) window.location.replace("/");
  }

  return <div></div>;
}

if (StorageHelper.isLoggedIn()) {
  var authData = StorageHelper.getAuthData();

  axios.defaults.headers.common["username"] = authData.username;
  axios.defaults.headers.common["password"] = authData.password;
}

export default function App({ ual }) {
  var [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    setLoggedIn(StorageHelper.isLoggedIn());
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router basename="/admin-ui">
        <div>
          <Test loggedIn={loggedIn} />

          {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
          <Switch>
            <Route exact path="/">
              {loggedIn ? (
                <DashboardLayout>
                  <Dashboard />
                </DashboardLayout>
              ) : (
                <Login />
              )}
            </Route>
            <Route path="/roundStatistics">
              <DashboardLayout>
                <RoundStats />
              </DashboardLayout>
            </Route>
            <Route path="/configurations">
              <DashboardLayout>
                <Configurations />
              </DashboardLayout>
            </Route>
            <Route path="/control">
              <DashboardLayout>
                <Control />
              </DashboardLayout>
            </Route>
            <Route path="/players">
              <DashboardLayout>
                <Players />
              </DashboardLayout>
            </Route>
            <Route path="/claims">
              <DashboardLayout>
                <Claims />
              </DashboardLayout>
            </Route>
            <Route path="/opens">
              <DashboardLayout>
                <Opens />
              </DashboardLayout>
            </Route>
            <Route path="/loots">
              <DashboardLayout>
                <Loots />
              </DashboardLayout>
            </Route>
            <Route path="/market">
              <DashboardLayout>
                <Market ual={ual} />
              </DashboardLayout>
            </Route>
            <Route path="/distributions">
              <DashboardLayout>
                <Distributions />
              </DashboardLayout>
            </Route>
            <Route path="/sponsors">
              <DashboardLayout>
                <Sponsors ual={ual} />
              </DashboardLayout>
            </Route>
            <Route path="/airdrop">
              <DashboardLayout>
                <Airdrop ual={ual} />
              </DashboardLayout>
            </Route>
          </Switch>
        </div>
      </Router>
    </ThemeProvider>
  );
}
