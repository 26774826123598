import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import Constants from "../../Constants";
import StorageHelper from "../../helpers/StorageHelper";
import { StringInput, FloatInput, IntegerInput } from "../generic/Inputs";
import {
  Button,
  Tooltip,
  CircularProgress,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import FormDialog from "../generic/FormDialog";
import TransactionsHelper from "../../helpers/TransactionsHelper";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Inventory as InventoryIcon,
  LinkOff as LinkOffIcon,
  Link as LinkIcon,
} from "@mui/icons-material";

const packSchema = "packs";
function AirdropUtility({ ual }) {
  const [recipients, setRecipients] = useState("");
  const [loading, setLoading] = useState(false);  

  const createAirdropAction = (new_asset_owner) => {
    var data = {
      authorized_minter: ual.activeUser.accountName,
      collection_name: Constants.DEFAULT_COLLECTION,
      immutable_data: [],
      mutable_data: [],
      new_asset_owner,
      schema_name: packSchema,
      template_id: Constants.STARTER_PACK_ID,
      tokens_to_back: [],
    };

    const action = {
      account: Constants.ATOMICASSETS,
      name: "mintasset",
      authorization: [
        {
          actor: TransactionsHelper.ual.activeUser.accountName,
          permission: "active",
        },
      ],
      data,
    };

    return action;
  };

  const doAirdrop = async () => {

      try {
        let _recipients = recipients.split(",");
        if(_recipients.length > 10){
          alert("Please have 10 recipients at a time")
          return;
        }

        let actions = _recipients.map(_recipient => createAirdropAction(_recipient));
        let success = await TransactionsHelper.SignTransaction(actions);

        if (success) {
          alert("Airdrop Successful");

        } else {
          alert("Airdrop failed");
        }

        setLoading(false);
      } catch (error) {
        console.log("error", error);
      }
  }

  return (
    <div>
      <TextareaAutosize
        aria-label="recipients"
        minRows={10}
        placeholder="Recipients"
        style={{ width: "80%", marginInline: "10%" }}
        onChange={(e) => {
          console.log("Recipients", e.target.value)
          setRecipients(e.target.value);
        }}
        value={recipients}
      />
      <Button variant="contained" color="primary" 
        style={{ width: "80%", marginInline: "10%" }} onClick={doAirdrop}>Airdrop</Button>
    </div>
  );
}

export default function Items({ ual }) {
  useEffect(() => {
    if (ual.activeUser) {
      console.log("A user is logged in, please wait...");
      TransactionsHelper.ual = ual;
    }
  }, [ual.activeUser]);

  return (
    <>
      {!ual.activeUser && (
        <Box
          sx={{
            width: "100%",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <Button variant="contained" color="error" onClick={ual.showModal}>
            <LinkIcon /> Connect Wallet
          </Button>
          <br />
          <Typography>Connect with a wallet with mint authority</Typography>
        </Box>
      )}
      {ual.activeUser && <AirdropUtility ual={ual} />}
    </>
  );
}
