import React, { useState } from "react";
import axios from "axios";
import Constants from "../Constants";
import StorageHelper from "../helpers/StorageHelper";
import { Button, TextField, Typography } from "@mui/material";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [responseColor, setResponseColor] = useState("blue");
  const [responseMessage, setResponseMessage] = useState("");

  const login = async () => {
    if (username == "" || password == "") {
      setResponseColor("red");
      setResponseMessage("Please input your username and password");
      return;
    }

    setResponseColor("blue");
    setResponseMessage("Logging in");
    console.log("Constants.API_URL", Constants.API_URL);
    try {
      let config = {
        headers: {
          username,
          password,
        },
      };

      let res = await axios.post(Constants.API_URL + "/login", {}, config);
      let data = res.data;
      if (data.success) {
        StorageHelper.setAuthData(username, password);
        setResponseColor("green");
        setResponseMessage("Login successful");
        window.location.reload();
      } else {
        setResponseColor("red");
        setResponseMessage("Login failed");
      }
    } catch (error) {
      console.log("Login error", error);
      setResponseColor("red");
      setResponseMessage("Login failed");
    }
  };

  return (
    <div
      className="container"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <div style={{ maxWidth: 400 }}>
        <Typography variant="h3" style={{ textAlign: "center" }}>
          {Constants.APP_NAME} Admin
        </Typography>
        <TextField
          type="text"
          label="Username"
          style={{ width: "90%" }}
          onChange={(e) => {
            setUsername(e.target.value);
          }}
          value={username}
          id="username"
        />
        <br />
        <TextField
          type="password"
          label="Password"
          sx={{ my: 2 }}
          style={{ width: "90%" }}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          value={password}
          id="password"
        />
        <br />
        <span style={{ color: responseColor }}>{responseMessage}</span>
        <br />
        <br />
        <Button
          color="success"
          variant="contained"
          className="btn1 bg-green w100"
          onClick={login}
        >
          Login
        </Button>
      </div>
    </div>
  );
}
