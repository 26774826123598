import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import Constants from "../Constants";
import { format, formatDistance, subDays  } from "date-fns";
import StorageHelper from "../helpers/StorageHelper";
import "./Spins.css";
import { StringInput, FloatInput, IntegerInput } from "./generic/Inputs";
import { Button, Tooltip, Typography } from "@mui/material";
import FormDialog from "./generic/FormDialog";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  AddCard as AddCardIcon,
} from "@mui/icons-material";
import StripedDataGrid from "./generic/StripedDataGrid";

export default function Distributions() {
  const defaultDistributionKey = "";
  const defaultTitle = "";
  const defaultBalance = 0;
  const defaultTokens = 0;

  const [loading, setLoading] = useState(true);
  const [fetchedDistributions, setFetchedDistributions] = useState([]);
  const [distributions, setDistributions] = useState([]);
  const [modalOn, setModalOn] = useState(false);
  const [distributeModalOn, setDistributeModalOn] = useState(false);
  const [modalTitle, setModalTitle] = useState("Create Distribution");
  const [saveSuccessMessage, setSaveSuccessMessage] = useState(
    "Distribution created successfully"
  );
  const [syncing, setSyncing] = useState(false);

  //inputs
  const [distributionKey, setDistributionKey] = useState(
    defaultDistributionKey
  );
  const [title, setTitle] = useState(defaultTitle);
  const [balance, setBalance] = useState(defaultBalance);
  const [tokens, setTokens] = useState(defaultTokens);

  async function fetchData() {
    const payload = {
      filter: {},
    };
    console.log("Constants.API_URL", Constants.API_URL);
    console.log("payload", payload);
    try {
      let res = await axios.get(Constants.API_URL + "/distributions", payload);
      console.log("res", res);
      let data = res.data;
      console.log("data", data);
      setFetchedDistributions([...data.distributions.slice().reverse()]);
      setDistributions(data.distributions.slice().reverse());
      setLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const loadDefaults = async () => {
    setModalTitle("Create Distribution");
    setSaveSuccessMessage("Distribution created successfully");
    setDistributionKey(defaultDistributionKey);
    setTitle(defaultTitle);
    setBalance(defaultBalance);
    setTokens(defaultTokens);
  };

  const createNewDistribution = () => {
    loadDefaults();
    setModalOn(true);
  };

  const distribute = async () => {
    setSyncing(true);
    const payload = {
      key: distributionKey,
      tokens: parseFloat(tokens.toFixed(4)),
    };

    try {
      let res = await axios.post(
        Constants.API_URL + "/distributions/distribute",
        {
          ...payload,
        }
      );
      console.log("res", res);
      res = res.data;
      if (res.success) {
        await fetchData();
      }

      setDistributeModalOn(false);

      setLoading(false);
      setSyncing(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      setSyncing(false);
    }
  };

  const openDistributeModal = (_distributionKey, _distributionTitle) => {
    setModalTitle("Distribute tokens to " + _distributionTitle);
    setDistributionKey(_distributionKey);
    setDistributeModalOn(true);
  };

  const updateDistribution = (_distributionKey, _title, _balance) => {
    setModalTitle(`Update Distribution #${_distributionKey} ${_title}`);
    setSaveSuccessMessage("Distribution updated successfully");
    setDistributionKey(_distributionKey);
    setTitle(_title);
    setBalance(_balance);
    setModalOn(true);
  };

  const deleteDistribution = async function (_key, index) {
    setSyncing(true)
    const payload = {
      data: {
        key: _key,
      },
    };
    try {
      let res = await axios.delete(Constants.API_URL + "/distributions", {
        ...payload,
      });
      console.log("res", res);
      res = res.data;
      if (res.success) {
        if (res.result.deletedCount > 0) {
          alert("Distribution deleted successfully");

          //Update client distribution data
          const fchdDistributions = [...fetchedDistributions];
          const oldDistributions = [...distributions];
          const fchdDistributionsIndex = oldDistributions.findIndex(
            (distribution) => {
              const distribution_id = distribution._id.toString();
              return distribution_id == _id;
            }
          );
          fchdDistributions.splice(fchdDistributionsIndex, 1);
          oldDistributions.splice(index, 1);
          setFetchedDistributions([...fchdDistributions]);
          setDistributions([...oldDistributions]);
        } else {
          alert("No distribution data was deleted");
        }
      }

      setLoading(false);
      setSyncing(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      setSyncing(false);
    }
  };

  const saveDistribution = async function () {
    if (
      distributionKey == "" ||
      title == ""
    ) {
      alert("Please fill out all fields");
      return;
    }
    setSyncing(true);
    const payload = {
      key: distributionKey,
      title,
      tokens: balance,
    };

    console.log("Constants.API_URL", Constants.API_URL);
    console.log("payload", payload);
    try {
      let res = await axios.post(Constants.API_URL + "/distributions", {
        ...payload,
      });
      console.log("res", res);
      res = res.data;
      if (res.success) {
        alert(saveSuccessMessage);
        await fetchData();

        //Close modal if not create mode
        if (saveSuccessMessage != "Created Distribution Successfully") {
          setModalOn(false);
        }
      } else {
        alert("Distribution creation failed");
      }

      setLoading(false);
      setSyncing(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      setSyncing(false);
    }
  };

  const tableCols = [
    { field: "id", headerName: "ID", width: 150 },
    { field: "title", headerName: "Name", width: 150 },
    { field: "balance", headerName: "Distributed", width: 150 },
    { field: "last_distribution", headerName: "Last Distribution", width: 150 },
    {
      field: "controls",
      headerName: "Controls",
      width: 400,
      renderCell: (params) => (
        <>
          <Tooltip title="Distribute">
            <Button
              variant="contained"
              color="info"
              key={"open_distribute_" + params.id}
              onClick={() => {
                openDistributeModal(params.row.distributionKey, params.row.title);
              }}
            >
              <AddCardIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Edit">
            <Button
              sx={{ ml: 2 }}
              variant="contained"
              color="warning"
              key={"edit_" + params.id}
              onClick={() => {
                updateDistribution(
                  params.row.distributionKey,
                  params.row.title,
                  params.row.balance
                );
              }}
            >
              <EditIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              variant="contained"
              color="error"
              key={"delete_" + params.id}
              sx={{ ml: 2 }}
              onClick={() => {
                var confirmed = window.confirm(
                  "Are you sure you want to delete data for distribution:" +
                    params.row.template_name +
                    "?"
                );
                if (confirmed)
                  deleteDistribution(
                    params.row.distributionKey,
                    params.row.index
                  );
              }}
            >
              <DeleteIcon />
            </Button>
          </Tooltip>
        </>
      ),
    },
  ];

  const tableRows = useMemo(() => {
    return distributions.map((_data, index) => {
      const id = _data._id.toString();
      const last_distribution = _data.distribution_logs.length > 0 ? format(new Date(_data.distribution_logs[_data.distribution_logs.length - 1].log_date), "MM/dd/yyyy") : "No distribution yet";
      console.log({ _data, last_distribution });
      return {
        ..._data,
        id,
        index,
        last_distribution
      };
    });
  }, [distributions]);

  return (
    <div style={loading ? { textAlign: "center" } : {}}>
      {/** StartModal */}
      <FormDialog
        style={{ width: "100%" }}
        open={modalOn}
        setOpen={setModalOn}
        title={modalTitle}
        isActive={modalOn}
        description=""
        onSubmit={saveDistribution}
        processing={syncing}
      >
        <StringInput
          id="template-key"
          label="Key"
          onValueChanged={setDistributionKey}
          value={distributionKey}
          style={{
            width: "100%",
          }}
        />{" "}
        <br />
        <StringInput
          id="template-title"
          label="Title"
          onValueChanged={setTitle}
          value={title}
          style={{
            width: "100%",
          }}
        />{" "}
        <br />
        <FloatInput
          id="template-balance"
          label="Initial Distribution"
          onValueChanged={setBalance}
          value={balance}
          style={{
            width: "100%",
          }}
        />{" "}
      </FormDialog>
      {/** EndModal */}

      {/** StartModal Distribute */}
      <FormDialog
        style={{ width: "100%" }}
        open={distributeModalOn}
        setOpen={setDistributeModalOn}
        title={modalTitle}
        isActive={distributeModalOn}
        description=""
        onSubmit={distribute}
        processing={syncing}
      >
        <br />
        <FloatInput
          id="template-balance"
          label="Initial Distribution"
          onValueChanged={setTokens}
          value={tokens}
          style={{
            width: "100%",
          }}
        />{" "}
      </FormDialog>
      {/** EndModal Distribute */}

      {loading ? (
        <h3>Fetching data...</h3>
      ) : (
        <div>
          <Button
            sx={{
              ml: 2,
            }}
            variant="contained"
            color="info"
            startIcon={<AddIcon />}
            onClick={createNewDistribution}
          >
            Create Distribution
          </Button>

          <div style={{ height: "80vh", width: "100%" }}>
            <StripedDataGrid
              rows={tableRows}
              columns={tableCols}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
              }
            />
          </div>
        </div>
      )}
    </div>
  );
}
