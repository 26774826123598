"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Types of errors that the UALError class can represent
 */
var UALErrorType;
(function (UALErrorType) {
    UALErrorType["UAL"] = "UAL";
    UALErrorType["Login"] = "Login";
    UALErrorType["Logout"] = "Logout";
    UALErrorType["Signing"] = "Signing";
    UALErrorType["Validation"] = "Validation";
    UALErrorType["Initialization"] = "Initialization";
    UALErrorType["DataRequest"] = "DataRequest";
    UALErrorType["Unsupported"] = "Unsupported";
})(UALErrorType = exports.UALErrorType || (exports.UALErrorType = {}));
/**
 * Base error class for UAL errors.
 */
class UALError extends Error {
    /**
     * @param message Message describing the error
     *
     * @param source The name of the authenticator that is the source of the error
     *
     * @param cause The underlying Error (if any) that resulted in the current one being thrown
     */
    constructor(message, type, cause, source) {
        super(message);
        this.type = type;
        this.cause = cause;
        this.source = source;
    }
}
exports.UALError = UALError;
