import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import Constants from "../../Constants";
import StorageHelper from "../../helpers/StorageHelper";
import { StringInput, FloatInput, IntegerInput } from "../generic/Inputs";
import {
  Button,
  Tooltip,
  CircularProgress,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import FormDialog from "../generic/FormDialog";
import TransactionsHelper from "../../helpers/TransactionsHelper";

import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Inventory as InventoryIcon,
  LinkOff as LinkOffIcon,
  Link as LinkIcon,
} from "@mui/icons-material";

import StripedDataGrid from "../generic/StripedDataGrid";

function ItemsDisplay({ ual }) {
  const defaultCollection = Constants.DEFAULT_COLLECTION;
  const defaultSchemaName = "packs";
  const defaultTemplateId = 0;
  const defaultRemaining = -1;
  const defaultTokenPrice = 0;
  const defaultTrophyPrice = 0;

  const [loading, setLoading] = useState(true);
  const [syncing, setSyncing] = useState(false);
  const [fetchedItems, setFetchedItems] = useState([]);
  const [items, setItems] = useState([]);
  const [modalOn, setModalOn] = useState(false);
  const [updateStocksModalOn, setUpdateStocksModalOn] = useState(false);
  const [modalTitle, setModalTitle] = useState("Create Item");
  const [saveSuccessMessage, setSaveSuccessMessage] = useState(
    "Item created successfully"
  );

  //inputs
  const [collectionName, setCollectionName] = useState(defaultCollection);
  const [schemaName, setSchemaName] = useState(defaultSchemaName);
  const [templateId, setTemplateId] = useState(defaultTemplateId);
  const [tokenPrice, setTokenPrice] = useState(defaultTokenPrice);
  const [trophyPrice, setTrophyPrice] = useState(defaultTrophyPrice);
  const [remaining, setRemaining] = useState(defaultRemaining);
  const [newStocks, setNewStocks] = useState(0);

  async function fetchData() {
    setSyncing(true);
    const payload = {
      filter: {},
    };
    console.log("Constants.API_URL", Constants.API_URL);
    console.log("payload", payload);
    try {
      let res = await axios.get(Constants.API_URL + "/market", payload);
      console.log("res", res);
      let data = res.data;
      console.log("data", data);
      setFetchedItems([...data.items.slice().reverse()]);
      setItems(data.items.slice().reverse());
      setLoading(false);
      setSyncing(false);
      
      axios.post(Constants.API_URL + "/reloadmarket", payload);
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const loadDefaults = async () => {
    setModalTitle("Create Item");
    setSaveSuccessMessage("Item created successfully");
    setCollectionName(defaultCollection);
    setSchemaName(defaultSchemaName);
    setTemplateId(defaultTemplateId);
    setRemaining(defaultRemaining);
    setTokenPrice(defaultTokenPrice);
    setTrophyPrice(defaultTrophyPrice);
  };

  const createNewItem = () => {
    loadDefaults();
    setModalOn(true);
  };

  const updateItem = (
    _templateName,
    _templateId,
    _schemaName,
    _collectionName,
    _tokenPrice,
    _trophyPrice,
    _remaining
  ) => {
    console.log({
      _templateId,
      _schemaName,
      _collectionName,
      _tokenPrice,
      _trophyPrice,
      _remaining,
    });
    setModalTitle(`Update Item #${_templateId} ${_templateName}`);
    setSaveSuccessMessage("Item updated successfully");
    setCollectionName(_collectionName);
    setSchemaName(_schemaName);
    setTemplateId(_templateId);
    setRemaining(_remaining);
    setTokenPrice(_tokenPrice);
    setTrophyPrice(_trophyPrice);
    setModalOn(true);
  };

  const updateStocks = (_templateId, _templateName, _remaining) => {
    setModalTitle(`Update Stocks #${_templateId} ${_templateName}`);
    setTemplateId(_templateId);
    setRemaining(_remaining);
    setUpdateStocksModalOn(true);
  };

  const createDeleteItemAction = (template_id) => {
    var data = {
      template_id,
    };

    console.log("createWithdrawAction data", data);

    const actions = [
      {
        account: Constants.MARKET_CONTRACT,
        name: "delmrktitem",
        authorization: [
          {
            actor: TransactionsHelper.ual.activeUser.accountName,
            permission: "active",
          },
        ],
        data,
      },
    ];

    return actions;
  };

  const deleteItem = async function (_id) {
    try {
      let actions = createDeleteItemAction(_id);
      let success = await TransactionsHelper.SignTransaction(actions);

      if (success) {
        alert("Deleted Item Successfully");
        fetchData();

        setModalOn(false);
      } else {
        alert("Item creation failed");
      }

      setLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  const createSaveItemAction = ({
    template_id,
    schema_name,
    collection_name,
    token_price,
    trophy_price,
  }) => {
    var data = {
      template_id,
      schema_name,
      collection_name,
      token_price,
      trophy_price,
    };

    console.log("createWithdrawAction data", data);

    const actions = [
      {
        account: Constants.MARKET_CONTRACT,
        name: "savemrktitem",
        authorization: [
          {
            actor: TransactionsHelper.ual.activeUser.accountName,
            permission: "active",
          },
        ],
        data,
      },
    ];

    return actions;
  };

  const saveItem = async function () {
    if (templateId == "" || collectionName == "" || schemaName == "") {
      alert("Please fill out all fields");
      return;
    }

    const payload = {
      template_id: templateId,
      collection_name: collectionName,
      schema_name: schemaName,
      token_price: tokenPrice.toFixed(4) + " " + Constants.TOKEN_SYMBOL,
      trophy_price: trophyPrice,
    };

    console.log("Constants.API_URL", Constants.API_URL);
    console.log("payload", payload);
    try {
      let actions = createSaveItemAction(payload);
      let success = await TransactionsHelper.SignTransaction(actions);

      if (success) {
        alert("Saved Item Successfully");
        fetchData();

        setModalOn(false);
      } else {
        alert("Item creation failed");
      }

      setLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  const createUpdateStocksAction = ({ template_id, stocks }) => {
    var data = {
      template_id,
      stocks,
    };

    console.log("createWithdrawAction data", data);

    const actions = [
      {
        account: Constants.MARKET_CONTRACT,
        name: "addmrktstck",
        authorization: [
          {
            actor: TransactionsHelper.ual.activeUser.accountName,
            permission: "active",
          },
        ],
        data,
      },
    ];

    return actions;
  };

  const saveStocks = async function () {
    try {
      console.log({ newStocks });
      let stocks = newStocks - remaining;
      let actions = createUpdateStocksAction({
        template_id: templateId,
        stocks,
      });
      let success = await TransactionsHelper.SignTransaction(actions);

      if (success) {
        alert("Stocks updated successfully");
        fetchData();
        setUpdateStocksModalOn(false);
      } else {
        alert("Stocks update failed");
      }
    } catch (error) {
      console.error({ error });
      alert("Stocks update failed");
    }
  };

  const tableCols = [
    { field: "id", headerName: "Template ID", width: 150 },
    { field: "name", headerName: "Template Name", width: 150 },
    { field: "schema_name", headerName: "Schema Name", width: 150 },
    { field: "collection_name", headerName: "Collection Name", width: 150 },
    { field: "token_price", headerName: "Token Price", width: 150 },
    { field: "trophy_price", headerName: "Trophy Price", width: 150 },
    { field: "remaining", headerName: "Remaining", width: 150 },
    {
      field: "controls",
      headerName: "Controls",
      width: 400,
      renderCell: (params) => (
        <>
          <Tooltip title="Edit">
            <Button
              variant="contained"
              color="warning"
              key={"edit_" + params.row.template_id}
              onClick={() => {
                updateItem(
                  params.row.name,
                  params.row.template_id,
                  params.row.schema_name,
                  params.row.collection_name,
                  params.row.token_price,
                  params.row.trophy_price,
                  params.row.remaining
                );
              }}
            >
              <EditIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Update Stocks">
            <Button
              variant="contained"
              sx={{ ml: 2 }}
              color="info"
              key={"update_stocks_" + params.row.template_id}
              onClick={() => {
                updateStocks(
                  params.row.template_id,
                  params.row.name,
                  params.row.remaining
                );
              }}
            >
              <InventoryIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              variant="contained"
              color="error"
              key={"delete_" + params.row.template_id}
              sx={{ ml: 2 }}
              onClick={() => {
                var confirmed = window.confirm(
                  "Are you sure you want to delete data for item:" +
                    params.row.name +
                    "?"
                );
                if (confirmed)
                  deleteItem(params.row.template_id);
              }}
            >
              <DeleteIcon />
            </Button>
          </Tooltip>
        </>
      ),
    },
  ];

  const tableRows = useMemo(() => {
    return items.map((_data, index) => {
      console.log({ _data });
      return {
        ..._data,
        index,
        name: _data.name,
        id: _data.template_id,
      };
    });
  }, [items]);

  return (
    <div style={loading ? { textAlign: "center" } : {}}>
      {/** StartModal */}
      <FormDialog
        style={{ width: "100%" }}
        open={modalOn}
        setOpen={setModalOn}
        title={modalTitle}
        isActive={modalOn}
        description=""
        onSubmit={saveItem}
      >
        <br />
        <IntegerInput
          id="template-id"
          label="Template ID"
          onValueChanged={setTemplateId}
          value={templateId}
          style={{
            width: "100%",
          }}
        />{" "}
        <br />
        <StringInput
          id="collection-name"
          label="Collection Name"
          onValueChanged={setCollectionName}
          value={collectionName}
          style={{
            width: "100%",
          }}
        />{" "}
        <br />
        <StringInput
          id="schema-name"
          label="Schema Name"
          onValueChanged={setSchemaName}
          value={schemaName}
          style={{
            width: "100%",
          }}
        />{" "}
        <br />
        <FloatInput
          id="token_price"
          label="Token Price"
          onValueChanged={setTokenPrice}
          value={tokenPrice}
          style={{
            width: "100%",
          }}
        />{" "}
        <br />
        <IntegerInput
          id="trophy_price"
          label="Trophy Price"
          onValueChanged={setTrophyPrice}
          value={trophyPrice}
          style={{
            width: "100%",
          }}
        />{" "}
        <br />
      </FormDialog>
      {/** EndModal */}

      {/** StartModal */}
      <FormDialog
        style={{ width: "100%" }}
        open={updateStocksModalOn}
        setOpen={setUpdateStocksModalOn}
        title={modalTitle}
        isActive={updateStocksModalOn}
        description=""
        onSubmit={saveStocks}
      >
        <IntegerInput
          id="new_stocks"
          label={`New Stocks - Remaining Stocks: ${remaining}`}
          onValueChanged={setNewStocks}
          value={newStocks}
          style={{
            width: "100%",
          }}
        />{" "}
        <br />
      </FormDialog>
      {/** EndModal */}

      {loading ? (
        <h3>Fetching data...</h3>
      ) : (
        <Box sx={{ width: "100%" }}>
          <Grid container>
            <Grid sx={{ display: "flex" }} item xs={12}>
              <Button
                sx={{
                  ml: 2,
                }}
                variant="contained"
                color="info"
                startIcon={<AddIcon />}
                onClick={createNewItem}
              >
                Create Item
              </Button>

              <Button
                sx={{
                  ml: 2,
                }}
                variant="contained"
                color="error"
                startIcon={<LinkOffIcon />}
                onClick={() => {
                  var confirmed = confirm(
                    "Are you sure that you want to disconnect your wallet?"
                  );
                  if (confirmed) {
                    TransactionsHelper.ual.logout();
                    window.location.reload();
                  }
                }}
              >
                Disconnect Wallet
              </Button>

              {syncing && (
                <>
                  <Typography sx={{ ml: 5, mt: 1 }}>
                    Syncing On-Chain Data
                  </Typography>
                  <CircularProgress size={"1rem"} sx={{ ml: 2 }} />
                </>
              )}
            </Grid>

            <Grid item xs={12} style={{ height: "80vh" }}>
              <StripedDataGrid
                rows={tableRows}
                columns={tableCols}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  );
}

export default function Items({ ual }) {
  useEffect(() => {
    if (ual.activeUser) {
      console.log("A user is logged in, please wait...");
      TransactionsHelper.ual = ual;
    }
  }, [ual.activeUser]);

  return (
    <>
      {!ual.activeUser && (
        <Box
          sx={{
            width: "100%",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <Button variant="contained" color="error" onClick={ual.showModal}>
            <LinkIcon /> Connect Wallet
          </Button>
          <br />
          <Typography>
            Connect with an admin wallet (wxdrbycntrct) to update market data
          </Typography>
        </Box>
      )}
      {ual.activeUser && <ItemsDisplay ual={ual} />}
    </>
  );
}
