import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import Constants from "../Constants";
import { format } from "date-fns";
import StorageHelper from "../helpers/StorageHelper";
import "./Spins.css";
import { StringInput, FloatInput, IntegerInput } from "./generic/Inputs";
import { Button, Tooltip, Box, Typography } from "@mui/material";
import FormDialog from "./generic/FormDialog";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  AddCard as AddCardIcon,
  Campaign as CampaignIcon,
  Link as LinkIcon,
  LinkOff as LinkOffIcon,
} from "@mui/icons-material";
import SimpleTable from "../components/SimpleTable";
import StripedDataGrid from "./generic/StripedDataGrid";
import FullScreenDialog from "./generic/FullscreenDialog";
import TransactionsHelper from "../helpers/TransactionsHelper";

function SponsorsDisplay() {
  const defaultSponsorId = "";
  const defaultSponsorName = "";
  const defaultSponsorshipName = "";
  const defaultTokensBought = 0;
  const defaultNftsMinted = 0;
  const defaultTemplateId = "";

  const [loading, setLoading] = useState(true);
  const [fetchedSponsors, setFetchedSponsors] = useState([]);
  const [sponsors, setSponsors] = useState([]);
  const [modalOn, setModalOn] = useState(false);
  const [sponsorshipModal, setSponsorshipModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("Create Sponsorship");
  const [saveSuccessMessage, setSaveSuccessMessage] = useState(
    "Sponsorship created successfully"
  );
  const [syncing, setSyncing] = useState(false);

  //inputs
  const [sponsorId, setSponsorId] = useState(defaultSponsorId);
  const [sponsorName, setSponsorName] = useState(defaultSponsorName);
  const [sponsorshipName, setSponsorshipName] = useState(
    defaultSponsorshipName
  );
  const [sponsorshipId, setSponsorshipId] = useState(defaultSponsorId);
  const [templateId, setTemplateId] = useState(defaultTemplateId);
  const [nftsMinted, setNftsMinted] = useState(defaultNftsMinted);
  const [tokenValue, setTokenValue] = useState(defaultTokensBought);
  const [viewSponsorshipModal, setViewSponsorshipModal] = useState(false);

  //Sponsor modal
  const [sponsorSponsorships, setSponsorSponsorships] = useState([]);
  const [sponsorIntent, setSponsorIntent] = useState("");
  const [sponsorshipIntent, setSponsorshipIntent] = useState("");

  async function fetchData() {
    const payload = {
      filter: {},
    };
    console.log("Constants.API_URL", Constants.API_URL);
    console.log("payload", payload);
    try {
      let res = await axios.get(Constants.API_URL + "/sponsors", payload);
      console.log("res", res);
      let data = res.data;
      console.log("data", data);
      setFetchedSponsors([...data.sponsors.slice().reverse()]);
      setSponsors(data.sponsors.slice().reverse());
      setLoading(false);

      return data.sponsors;
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const loadDefaults = async () => {
    setModalTitle("Create Sponsorship");
    setSaveSuccessMessage("Sponsorship created successfully");
    setSponsorshipName(defaultSponsorshipName);
    setSponsorshipId(defaultSponsorId);
    setTemplateId(defaultTemplateId);
    setNftsMinted(defaultNftsMinted);
    setTokenValue(defaultTokensBought);
  };

  const createNewSponsor = () => {
    loadDefaults();
    setSponsorName(defaultSponsorName);
    setSponsorIntent("Create");
    setModalOn(true);
  };

  const createNewSponsorship = () => {
    loadDefaults();
    setSponsorshipIntent("Create");
    setSponsorshipModal(true);
  };

  const createSetSponsorAction = ({ template_id, sponsor, token_value }) => {
    var data = {
      template_id,
      sponsor,
      token_value: token_value.toFixed(4) + " " + Constants.TOKEN_SYMBOL,
    };

    console.log("createWithdrawAction data", data);

    const actions = [
      {
        account: Constants.CONTRACT_ACCOUNT,
        name: "setsponsor",
        authorization: [
          {
            actor: TransactionsHelper.ual.activeUser.accountName,
            permission: "active",
          },
        ],
        data,
      },
    ];

    return actions;
  };

  const createRemoveSponsorAction = (template_id) => {
    var data = {
      template_id,
    };

    console.log("createRemoveSponsorAction data", data);

    const actions = [
      {
        account: Constants.CONTRACT_ACCOUNT,
        name: "delsponsor",
        authorization: [
          {
            actor: TransactionsHelper.ual.activeUser.accountName,
            permission: "active",
          },
        ],
        data,
      },
    ];

    console.log({actions})

    return actions;
  };

  const viewSponsor = (_id, _sponsorName, _sponsorships) => {
    setViewSponsorshipModal(true);
    setSponsorId(_id);
    setSponsorName(_sponsorName);
    setSponsorSponsorships(_sponsorships);
  };

  const updateSponsor = (_sponsorId, _sponsorName) => {
    setModalTitle(`Update Sponsor #${_sponsorId} ${_sponsorName}`);
    setSaveSuccessMessage("Sponsor updated successfully");
    setSponsorId(_sponsorId);
    setSponsorName(_sponsorName);
    setSponsorIntent("Update");
    setModalOn(true);
  };

  const updateSponsorship = (
    _id,
    _name,
    _tokenValue,
    _nftsMinted,
    _templateId
  ) => {
    setModalTitle(`Update Sponsorship #${_id} ${_name}`);
    setSaveSuccessMessage("Sponsorship updated successfully");
    _id, _name, _tokenValue, _nftsMinted, _templateId;
    setSponsorshipId(_id);
    setSponsorshipName(_name);
    setTokenValue(_tokenValue);
    setNftsMinted(_nftsMinted);
    setTemplateId(_templateId);

    setSponsorshipIntent("Update");
    setSponsorshipModal(true);
  };

  const deleteSponsorship = async function (_key, template_id) {
    setSyncing(true);
    const payload = {
      data: {
        id: _key,
      },
    };
    try {
      let actions = createRemoveSponsorAction(template_id);

      let success = await TransactionsHelper.SignTransaction(actions);

      if (success) {
        let res = await axios.delete(Constants.API_URL + "/sponsorships", {
          ...payload,
        });
        console.log("res", res);
        res = res.data;
        if (res.success) {
          if (res.result.result.deletedCount > 0) {
            alert("Sponsorship deleted successfully");

            let newSponsorData = await fetchData();
            let _sponsor = newSponsorData.find(
              (_sponsorData) => _sponsorData._id === sponsorId
            );
            console.log("test", {
              sponsorId,
              newSponsorData,
              newSponsorShips: _sponsor,
            });

            setSponsorSponsorships(_sponsor.sponsorships);
          } else {
            alert("No sponsorship data was deleted");
          }
        }

        setLoading(false);
        setSyncing(false);
      } else {
        setLoading(false);
        setSyncing(false);
        alert("No sponsorship data was deleted");
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      setSyncing(false);
    }
  };

  const deleteSponsor = async function (_key, index) {
    setSyncing(true);
    const payload = {
      data: {
        id: _key,
      },
    };
    try {
      let res = await axios.delete(Constants.API_URL + "/sponsors", {
        ...payload,
      });
      console.log("res", res);
      res = res.data;
      if (res.success) {
        if (res.result.result.deletedCount > 0) {
          alert("Sponsorship deleted successfully");

          //Update client sponsorship data
          const fchdSponsors = [...fetchedSponsors];
          const oldSponsors = [...sponsors];
          const fchdSponsorsIndex = oldSponsors.findIndex((_sponsor) => {
            const sponsor_id = _sponsor._id.toString();
            return sponsor_id == _key;
          });
          fchdSponsors.splice(fchdSponsorsIndex, 1);
          oldSponsors.splice(index, 1);
          setFetchedSponsors([...fchdSponsors]);
          setSponsors([...oldSponsors]);
        } else {
          alert("No sponsorship data was deleted");
        }
      }

      setLoading(false);
      setSyncing(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      setSyncing(false);
    }
  };

  const saveSponsor = async function () {
    if (sponsorName == "") {
      alert("Please input the sponsor's name");
      return;
    }
    setSyncing(true);
    const payload = {
      name: sponsorName,
    };

    console.log("Constants.API_URL", Constants.API_URL);
    console.log("payload", payload);
    try {
      let res = null;

      if (sponsorIntent == "Create") {
        res = await axios.post(Constants.API_URL + "/sponsors", {
          ...payload,
        });
      } else {
        res = await axios.put(Constants.API_URL + "/sponsors/" + sponsorId, {
          ...payload,
        });
      }

      console.log("res", res);
      res = res.data;
      if (res.success) {
        alert(saveSuccessMessage);
        await fetchData();
        //Close modal if not create mode
        if (saveSuccessMessage != "Added Sponsor Successfully") {
          setModalOn(false);
        }
      } else {
        alert("Sponsorship creation failed");
      }

      setLoading(false);
      setSyncing(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      setSyncing(false);
    }
  };

  const saveSponsorship = async function () {
    if (
      sponsorId == "" ||
      sponsorshipName == "" ||
      tokenValue == "" ||
      nftsMinted == "" ||
      templateId == ""
    ) {
      alert("Please fill out all fields");
      return;
    }
    setSyncing(true);
    const payload = {
      sponsor_id: sponsorId,
      name: sponsorshipName,
      token_value: parseFloat(tokenValue.toFixed(4)),
      nfts_minted: nftsMinted,
      template_id: templateId,
    };

    let actions = createSetSponsorAction({
      template_id: templateId,
      sponsor: sponsorName,
      token_value: parseFloat(tokenValue.toFixed(4)),
    });

    let success = await TransactionsHelper.SignTransaction(actions);

    if (success) {
      console.log("Constants.API_URL", Constants.API_URL);
      console.log("payload", payload);
      try {
        let res = null;

        if (sponsorshipIntent == "Create") {
          res = await axios.post(Constants.API_URL + "/sponsorships", {
            ...payload,
          });
        } else {
          res = await axios.put(
            Constants.API_URL + "/sponsorships/" + sponsorshipId,
            {
              ...payload,
            }
          );
        }
        console.log("res", res);
        res = res.data;
        if (res.success) {
          alert(saveSuccessMessage);
          let newSponsorData = await fetchData();
          let _sponsor = newSponsorData.find(
            (_sponsorData) => _sponsorData._id === sponsorId
          );
          console.log("test", {
            sponsorId,
            newSponsorData,
            newSponsorShips: _sponsor,
          });

          setSponsorSponsorships(_sponsor.sponsorships);
          setSponsorshipModal(false);

          //Close modal if not create mode
          if (saveSuccessMessage != "Created Sponsorship Successfully") {
            setSponsorshipModal(false);
          }
        } else {
          alert("Sponsorship creation failed");
        }

        setLoading(false);
        setSyncing(false);
      } catch (error) {
        console.log("error", error);
        setLoading(false);
        setSyncing(false);
      }
    } else {
      alert("Sponsorship creation failed");
      setLoading(false);
      setSyncing(false);
    }
  };

  const tableCols = [
    { field: "id", headerName: "ID", width: 150 },
    { field: "name", headerName: "Name", width: 150 },
    { field: "sponsorships_count", headerName: "Sponsorships", width: 150 },
    { field: "tokens_purchased", headerName: "Tokens Purchased", width: 150 },
    {
      field: "controls",
      headerName: "Controls",
      width: 400,
      renderCell: (params) => (
        <>
          <Tooltip title="View Sponsorships">
            <Button
              variant="contained"
              color="info"
              key={"view_sponsorships_btn_" + params.row._id}
              onClick={() => {
                viewSponsor(
                  params.row._id,
                  params.row.name,
                  params.row.sponsorships
                );
              }}
            >
              <CampaignIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Edit">
            <Button
              sx={{ ml: 2 }}
              variant="contained"
              color="warning"
              key={"edit_" + params.row._id}
              onClick={() => {
                updateSponsor(params.row._id, params.row.name);
              }}
            >
              <EditIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              variant="contained"
              color="error"
              key={"delete_" + params.row._id}
              sx={{ ml: 2 }}
              onClick={() => {
                var confirmed = window.confirm(
                  "Are you sure you want to delete data for sponsorship:" +
                    params.row.name +
                    "?"
                );
                if (confirmed) deleteSponsor(params.row._id, params.row.index);
              }}
            >
              <DeleteIcon />
            </Button>
          </Tooltip>
        </>
      ),
    },
  ];

  const tableRows = useMemo(() => {
    return sponsors.map((_data, index) => {
      const id = _data._id.toString();
      console.log({ _data });
      return {
        ..._data,
        id,
        index,
        sponsorships_count: _data.sponsorships.length,
        tokens_purchased: _data.sponsorships.reduce(
          (totalTokens, _sponsorship) => {
            console.log({ _sponsorship, totalTokens });
            return (
              totalTokens + _sponsorship.token_value * _sponsorship.nfts_minted
            );
          },
          0
        ),
      };
    });
  }, [sponsors]);

  let sponsorshipCols = [
    {
      content: "Template Name",
    },
    {
      content: "Token Value (Received per Burn)",
    },
    {
      content: "NFTs Minted",
    },
    {
      content: "Tokens Bought",
    },
    {
      content: "Template",
    },
    {
      content: "Date Created",
    },
    {
      content: "Controls",
    },
  ];
  let sponsorshipRows = [];

  sponsorshipRows = useMemo(() => {
    return sponsorSponsorships.map((_sponsorship) => {
      return {
        key: _sponsorship._id,
        cols: [
          {
            content: _sponsorship.name,
          },
          {
            content: _sponsorship.token_value,
          },
          {
            content: _sponsorship.nfts_minted,
          },
          {
            content: _sponsorship.token_value * _sponsorship.nfts_minted,
          },
          {
            content: _sponsorship.template_id,
          },
          {
            content: format(_sponsorship.create_date, "dd/MM/yyyy"),
          },
          {
            content: (
              <>
                <Tooltip title="Edit">
                  <Button
                    sx={{ ml: 2 }}
                    variant="contained"
                    color="warning"
                    key={"edit_" + _sponsorship._id}
                    onClick={() => {
                      updateSponsorship(
                        _sponsorship._id,
                        _sponsorship.name,
                        _sponsorship.token_value,
                        _sponsorship.nfts_minted,
                        _sponsorship.template_id
                      );
                    }}
                  >
                    <EditIcon />
                  </Button>
                </Tooltip>

                <Tooltip title="Delete">
                  <Button
                    variant="contained"
                    color="error"
                    key={"delete_" + _sponsorship._id}
                    sx={{ ml: 2 }}
                    onClick={() => {
                      var confirmed = window.confirm(
                        "Are you sure you want to delete data for sponsorship:" +
                          _sponsorship.name +
                          "?"
                      );
                      if (confirmed)
                        deleteSponsorship(
                          _sponsorship._id,
                          _sponsorship.template_id
                        );
                    }}
                  >
                    <DeleteIcon />
                  </Button>
                </Tooltip>
              </>
            ),
          },
        ],
      };
    });
  }, [sponsorSponsorships]);

  console.table(sponsorshipRows);

  return (
    <div style={loading ? { textAlign: "center" } : {}}>
      {/** StartModal */}
      <FormDialog
        style={{ width: "100%" }}
        open={modalOn}
        setOpen={setModalOn}
        title={modalTitle}
        isActive={modalOn}
        description=""
        onSubmit={saveSponsor}
        processing={syncing}
      >
        <StringInput
          id="template-key"
          label="Sponsor Name"
          onValueChanged={setSponsorName}
          value={sponsorName}
          style={{
            width: "100%",
          }}
        />{" "}
        <br />
      </FormDialog>
      {/** EndModal */}

      {/** StartModal Distribute */}
      <FormDialog
        style={{ width: "100%" }}
        open={sponsorshipModal}
        setOpen={setSponsorshipModal}
        title={modalTitle}
        isActive={sponsorshipModal}
        description=""
        onSubmit={saveSponsorship}
        processing={syncing}
      >
        <StringInput
          id="template-name"
          label="Template Name"
          onValueChanged={setSponsorshipName}
          value={sponsorshipName}
          style={{
            width: "100%",
          }}
        />{" "}
        <br />
        <FloatInput
          id="tokens-bought"
          label="Token Value (Received per Burn)"
          onValueChanged={setTokenValue}
          value={tokenValue}
          style={{
            width: "100%",
          }}
        />{" "}
        <br />
        <IntegerInput
          id="tokens-bought"
          label="NFTs Minted"
          onValueChanged={setNftsMinted}
          value={nftsMinted}
          style={{
            width: "100%",
          }}
        />{" "}
        <StringInput
          id="template-id"
          label="Template ID"
          onValueChanged={setTemplateId}
          value={templateId}
          style={{
            width: "100%",
          }}
        />{" "}
      </FormDialog>
      {/** EndModal Distribute */}

      {loading ? (
        <h3>Fetching data...</h3>
      ) : (
        <div>
          <Button
            sx={{
              ml: 2,
            }}
            variant="contained"
            color="info"
            startIcon={<AddIcon />}
            onClick={createNewSponsor}
          >
            Add Sponsor
          </Button>

          <Button
            sx={{
              ml: 2,
            }}
            variant="contained"
            color="error"
            startIcon={<LinkOffIcon />}
            onClick={() => {
              var confirmed = confirm(
                "Are you sure that you want to disconnect your wallet?"
              );
              if (confirmed) {
                TransactionsHelper.ual.logout();
                window.location.reload();
              }
            }}
          >
            Disconnect Wallet
          </Button>

          <div style={{ height: "80vh", width: "100%" }}>
            <StripedDataGrid
              rows={tableRows}
              columns={tableCols}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
              }
            />
          </div>
        </div>
      )}

      <FullScreenDialog
        title={sponsorName}
        setOpen={setViewSponsorshipModal}
        open={viewSponsorshipModal}
      >
        <SimpleTable
          mainKey="sponsorships"
          title="Sponsorships"
          controls={
            <>
              <Button
                sx={{
                  ml: 2,
                }}
                variant="contained"
                color="info"
                startIcon={<AddIcon />}
                onClick={createNewSponsorship}
              >
                Add Sponsorship
              </Button>
            </>
          }
          colData={sponsorshipCols}
          rowData={sponsorshipRows}
        />
      </FullScreenDialog>
    </div>
  );
}

export default function Sponsors({ ual }) {
  useEffect(() => {
    if (ual.activeUser) {
      console.log("A user is logged in, please wait...");
      TransactionsHelper.ual = ual;
    }
  }, [ual.activeUser]);

  return (
    <>
      {!ual.activeUser && (
        <Box
          sx={{
            width: "100%",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <Button variant="contained" color="error" onClick={ual.showModal}>
            <LinkIcon /> Connect Wallet
          </Button>
          <br />
          <Typography>
            Connect with an admin wallet (wxdrbycntrct) to update market data
          </Typography>
        </Box>
      )}
      {ual.activeUser && <SponsorsDisplay ual={ual} />}
    </>
  );
}
